import React from 'react';
import {NavLink} from 'react-router-dom';

export const NotFound = () => {
    return (
        <div class={`error-container`}>
            <h1>Dude! Where is my page?</h1>
            <div className="error-container--description">I have bad news for you. The page you are looking for might be
                removed or is temporarily unavailable.
            </div>
            <div className="error-container--button"><NavLink to={`/`} className="btn btn-primary btn-large"
                                                              end>Home</NavLink></div>

            <figure className="error-container--figure">
                <img src="/img/404.svg" alt=""/>
            </figure>
        </div>
    );
};