export const templateConfig = () => {
    return [
        {
            'category': 'Person',
            'key': 'full_name',
            'label': 'Full Name',
            'name': 'full_name',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Person',
            'key': 'age',
            'label': 'Age',
            'name': 'age',
            'type': 'integer',
            'fields': {
                'min': {
                    'type': 'number',
                    'label': 'From:',
                    'value': 1,
                    attr: {
                        min: 1,
                        max: 120
                    }
                },
                'max': {
                    'type': 'number',
                    'label': 'To:',
                    'value': 120,
                    attr: {
                        min: 1,
                        max: 120
                    }
                },
            },
            active: true
        },
        {
            'category': 'Person',
            'key': 'bio',
            'label': 'Bio',
            'name': 'bio',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Person',
            'key': 'birthday',
            'label': 'Birthday',
            'name': 'birthday',
            'type': 'date',
            'fields': {
                'min': {
                    'type': 'number',
                    'label': 'From (Year):',
                    'value': 18
                },
                'max': {
                    'type': 'number',
                    'label': 'To (Year):',
                    'value': 30
                },
            },
            active: true
        },
        {
            'category': 'Person',
            'key': 'first',
            'label': 'First name',
            'name': 'firstname',
            'type': 'varchar',
            'fields': {},
            default: true,
            order: 1,
            active: true
        },
        {
            'category': 'Person',
            'key': 'zodiacSign',
            'label': 'Zodiac Sign',
            'name': 'zodiacSign',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Person',
            'key': 'gender',
            'label': 'Gender',
            'name': 'gender',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Person',
            'key': 'prefix',
            'label': 'Prefix',
            'name': 'prefix',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Person',
            'key': 'last',
            'label': 'Last name',
            'name': 'lastname',
            'type': 'varchar',
            'fields': {},
            default: true,
            order: 2,
            active: true
        },
        {
            'category': 'Person',
            'key': 'middle_name',
            'label': 'Middle Name',
            'name': 'middle_name',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Person',
            'key': 'sex',
            'label': 'Sex',
            'name': 'sex',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Random',
            'key': 'alpha',
            'label': 'Alpha',
            'name': 'alpha',
            'type': 'varchar',
            'fields': {
                'count': {
                    'type': 'number',
                    'label': 'From:',
                    'value': 1
                },
            },
            active: true
        },
        {
            'category': 'Image',
            'key': 'avatar',
            'label': 'Avatar',
            'name': 'avatar',
            'type': 'text',
            'fields': {},
            active: true
        },
        {
            'category': 'Datatype',
            'key': 'bool',
            'label': 'Bool',
            'name': 'bool',
            'type': 'tinyint',
            'fields': {},
            active: true
        },
        {
            'category': 'Random',
            'key': 'char',
            'label': 'Char',
            'name': 'char',
            'type': 'varchar',
            'fields': {
                'text': {
                    'type': 'text',
                    'label': 'Letter:',
                    'value': 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()'
                }
            },
            active: true
        },
        {
            'category': 'Address',
            'key': 'city',
            'label': 'City',
            'name': 'city',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'creditCardCVV',
            'label': 'Credit Card CVV',
            'name': 'creditCardCVV',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'creditCardIssuer',
            'label': 'Credit Card Issuer',
            'name': 'creditCardIssuer',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'creditCardNumber',
            'label': 'Credit Card Number',
            'name': 'creditCardNumber',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'accountName',
            'label': 'Account Name',
            'name': 'accountName',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'accountNumber',
            'label': 'Account Number',
            'name': 'accountNumber',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'currencyCode',
            'label': 'Currency Code',
            'name': 'currencyCode',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'currencyName',
            'label': 'Currency Name',
            'name': 'currencyName',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'currencySymbol',
            'label': 'Currency Symbol',
            'name': 'currencySymbol',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'ethereumAddress',
            'label': 'Ethereum Address',
            'name': 'ethereumAddress',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'iban',
            'label': 'Iban',
            'name': 'iban',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'litecoinAddress',
            'label': 'Litecoin Address',
            'name': 'litecoinAddress',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'maskedNumber',
            'label': 'Masked Number',
            'name': 'maskedNumber',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'routingNumber',
            'label': 'Routing Number',
            'name': 'routingNumber',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'transactionDescription',
            'label': 'Transaction Description',
            'name': 'transactionDescription',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'transactionType',
            'label': 'Transaction Type',
            'name': 'transactionType',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Datatype',
            'key': 'date',
            'label': 'Date',
            'name': 'date',
            'type': 'date',
            'fields': {
                'min': {
                    'type': 'date',
                    'label': 'From:',
                    'value': ''
                },
                'max': {
                    'type': 'date',
                    'label': 'To:',
                    'value': ''
                },
                'format': {
                    'type': 'text',
                    'label': 'Format:',
                    'value': 'MMM Do YY'
                }
            },
            active: true
        },
        {
            'category': 'Datatype',
            'key': 'digit',
            'label': 'Digit',
            'name': 'digit',
            'type': 'integer',
            'fields': {
                'length': {
                    'type': 'number',
                    'label': 'Length:',
                    'value': 5
                }
            },
            active: true
        },
        {
            'category': 'Finance',
            'key': 'amount',
            'label': 'Amount',
            'name': 'amount',
            'type': 'varchar',
            'fields': {
                'min': {
                    'type': 'number',
                    'label': 'Min:',
                    'value': 5,
                    attr: {
                        min: 1,
                        max: 10
                    }
                },
                'max': {
                    'type': 'number',
                    'label': 'Max:',
                    'value': 10
                },
                'currency': {
                    'type': 'text',
                    'label': 'Choose currency:',
                    'value': '$',
                }
            },
            active: true
        },
        {
            'category': 'Finance',
            'key': 'bic',
            'label': 'Bic',
            'name': 'bic',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Finance',
            'key': 'bitcoinAddress',
            'label': 'Bitcoin Address',
            'name': 'bitcoinAddress',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Internet',
            'key': 'domain',
            'label': 'Domain',
            'name': 'domain',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Internet',
            'key': 'domainSuffix',
            'label': 'Domain suffix',
            'name': 'domain_suffix',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Internet',
            'key': 'email',
            'label': 'Email',
            'name': 'email',
            'type': 'varchar',
            'fields': {},
            default: true,
            order: 3,
            active: true
        },
        {
            'category': 'Internet',
            'key': 'emailExample',
            'label': 'Email Example',
            'name': 'email_example',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Internet',
            'key': 'emoji',
            'label': 'Emoji',
            'name': 'emoji',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Datatype',
            'key': 'float',
            'label': 'Float',
            'name': 'float',
            'type': 'float',
            'fields': {
                'min': {
                    'type': 'number',
                    'label': 'From:',
                    'value': 1
                },
                'max': {
                    'type': 'number',
                    'label': 'To:',
                    'value': 1000
                },
                'scale': {
                    'type': 'number',
                    'label': 'Scale:',
                    'value': 2
                }
            },
            active: true
        },
        {
            'category': 'Datatype',
            'key': 'guid',
            'label': 'GUID',
            'name': 'guid',
            'type': 'varchar',
            'fields': [],
            active: true
        },
        {
            'category': 'Color',
            'key': 'human',
            'label': 'Human',
            'name': 'human',
            'type': 'varchar',
            'fields': [],
            active: true
        },
        {
            'category': 'Internet',
            'key': 'httpMethod',
            'label': 'httpMethod',
            'name': 'http_method',
            'type': 'varchar',
            'fields': [],
            active: true
        },
        {
            'category': 'Internet',
            'key': 'httpStatusCode',
            'label': 'httpStatusCode',
            'name': 'http_status_code',
            'type': 'integer',
            'fields': [],
            active: true
        },
        {
            'category': 'Phone',
            'key': 'imei',
            'label': 'Imei',
            'name': 'imei',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Datatype',
            'key': 'integer',
            'label': 'Integer',
            'name': 'integer',
            'type': 'integer',
            'fields': {},
            active: true
        },
        {
            'category': 'Internet',
            'key': 'ip',
            'label': 'ip',
            'name': 'ip',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Internet',
            'key': 'ipv6',
            'label': 'ipv6',
            'name': 'ipv6',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Address',
            'key': 'latitude',
            'label': 'Latitude',
            'name': 'latitude',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Address',
            'key': 'longitude',
            'label': 'Longitude',
            'name': 'longitude',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Lorem',
            'key': 'lines',
            'label': 'Lines',
            'name': 'lines',
            'type': 'varchar',
            'fields': {
                'count': {
                    'type': 'number',
                    'label': 'Count:',
                    'value': 1
                },
            },
            active: true
        },
        {
            'category': 'Internet',
            'key': 'mac',
            'label': 'Mac',
            'name': 'mac',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Random',
            'key': 'mi',
            'label': 'mi',
            'name': 'mi',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Random',
            'key': 'natural',
            'label': 'Natural',
            'name': 'natural',
            'type': 'integer',
            'fields': {
                'max': {
                    'type': 'number',
                    'label': 'Max:',
                    'value': 99999
                },
            },
            active: true
        },
        {
            'category': 'Lorem',
            'key': 'paragraph',
            'label': 'Paragraph',
            'name': 'paragraph',
            'type': 'text',
            'fields': {
                'sentenceCount': {
                    'type': 'number',
                    'label': 'Sentence count:',
                    'value': 3
                },
            },
            active: true
        },
        {
            'category': 'Internet',
            'key': 'password',
            'label': 'Password',
            'name': 'password',
            'type': 'varchar',
            'fields': {
                'encode': {
                    'type': 'select',
                    'label': 'Encode',
                    'value': false,
                    'values': [
                        {
                            label: 'None',
                            value: false
                        },
                        {
                            label: 'CRC-16',
                            value: 'CRC-16'
                        },
                        {
                            label: 'CRC-32',
                            value: 'CRC-32'
                        },
                        {
                            label: 'MD4',
                            value: 'MD4'
                        },
                        {
                            label: 'MD5',
                            value: 'MD5'
                        },
                        {
                            label: 'SHA1',
                            value: 'SHA1'
                        },
                        {
                            label: 'SHA256',
                            value: 'SHA256'
                        },
                        {
                            label: 'SHA384',
                            value: 'SHA384'
                        },
                        {
                            label: 'SHA512',
                            value: 'SHA512'
                        },
                        {
                            label: 'SHA512/256',
                            value: 'SHA512/256'
                        },
                        {
                            label: 'SHA3-224',
                            value: 'SHA3-224'
                        },
                        {
                            label: 'SHA3-256',
                            value: 'SHA3-256'
                        },
                        {
                            label: 'SHA3-384',
                            value: 'SHA3-384'
                        },
                        {
                            label: 'SHA3-512',
                            value: 'SHA3-512'
                        },
                        {
                            label: 'Keccak-224',
                            value: 'Keccak-224'
                        },
                        {
                            label: 'Keccak-256',
                            value: 'Keccak-256'
                        },
                        {
                            label: 'Keccak-384',
                            value: 'Keccak-384'
                        },
                        {
                            label: 'Keccak-512',
                            value: 'Keccak-512'
                        },
                        {
                            label: 'Shake-128',
                            value: 'Shake-128'
                        },
                        {
                            label: 'Shake-256',
                            value: 'Shake-256'
                        },
                        {
                            label: 'Base32',
                            value: 'Base32'
                        },
                        {
                            label: 'Base64',
                            value: 'Base64'
                        },
                    ]
                },
            },
            active: true
        },
        {
            'category': 'Random',
            'key': 'pick',
            'label': 'Pick',
            'name': 'pick',
            'type': 'text',
            'fields': {
                'text': {
                    'type': 'textarea',
                    'label': 'Word list (one per line):',
                    'value': ''
                }
            },
            active: true
        },
        {
            'category': 'Phone',
            'key': 'phone',
            'label': 'Phone',
            'name': 'phone',
            'type': 'varchar',
            'fields': {
                'format': {
                    'type': 'text',
                    'label': 'Format:',
                    'value': '+1 (###) ###-####'
                },
            },
            active: true
        },
        {
            'category': 'Internet',
            'key': 'port',
            'label': 'Port',
            'name': 'port',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Address',
            'key': 'postal',
            'label': 'Postal',
            'name': 'postal',
            'type': 'varchar',
            'fields': {
                'format': {
                    'type': 'text',
                    'label': 'Format:',
                    'value': '###-###'
                },
            },
            active: true
        },
        {
            'category': 'Internet',
            'key': 'protocol',
            'label': 'Protocol',
            'name': 'protocol',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Unique',
            'key': 'seq',
            'label': 'Seq',
            'name': 'id',
            'type': 'integer',
            'fields': {
                'from': {
                    'type': 'number',
                    'label': 'From:',
                    'value': 1
                },
            },
            'default': true,
            'order': 0,
            active: true
        },
        {
            'category': 'Color',
            'key': 'rgb',
            'label': 'RGB',
            'name': 'rgb',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Color',
            'key': 'space',
            'label': 'Space',
            'name': 'space',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Color',
            'key': 'cmyk',
            'label': 'CMYK',
            'name': 'cmyk',
            'type': 'varchar',
            'fields': {
                'format': {
                    'type': 'select',
                    'label': 'Encode',
                    'value': 'binary',
                    'values': [
                        {
                            label: 'binary',
                            value: 'binary'
                        },
                        {
                            label: 'css',
                            value: 'css'
                        },
                        {
                            label: 'decimal',
                            value: 'decimal'
                        }
                    ]
                },
            },
            active: true
        },
        {
            'category': 'Lorem',
            'key': 'sentence',
            'label': 'Sentence',
            'name': 'sentence',
            'type': 'text',
            'fields': {
                'num': {
                    'type': 'number',
                    'label': 'Num:',
                    'value': 1
                },
            },
            active: true
        },
        {
            'category': 'Lorem',
            'key': 'slug',
            'label': 'Slug',
            'name': 'slug',
            'type': 'varchar',
            'fields': {
                'num': {
                    'type': 'number',
                    'label': 'Word count:',
                    'value': 3
                },
            },
            active: true
        },
        {
            'category': 'Address',
            'key': 'state',
            'label': 'State',
            'name': 'state',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Address',
            'key': 'street',
            'label': 'Street',
            'name': 'street',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Address',
            'key': 'secondaryAddress',
            'label': 'Secondary Address',
            'name': 'secondaryAddress',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Datatype',
            'key': 'string',
            'label': 'String',
            'name': 'string',
            'type': 'varchar',
            'fields': {
                'length': {
                    'type': 'number',
                    'label': 'Length:',
                    'value': 10
                },
            },
            active: true
        },
        {
            'category': 'Address',
            'key': 'timezone',
            'label': 'Timezone',
            'name': 'timezone',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Internet',
            'key': 'url',
            'label': 'url',
            'name': 'url',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Internet',
            'key': 'userAgent',
            'label': 'User Agent',
            'name': 'user_agent',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Internet',
            'key': 'userName',
            'label': 'User Name',
            'name': 'user_name',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Lorem',
            'key': 'word',
            'label': 'Word',
            'name': 'word',
            'type': 'varchar',
            'fields': {
                'length': {
                    'type': 'number',
                    'label': 'Length:',
                    'value': 5
                },
            },
            active: true
        },
        {
            'category': 'Random',
            'key': 'yn',
            'label': 'Y-N',
            'name': 'yn',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Address',
            'key': 'buildingNumber',
            'label': 'Building Number',
            'name': 'buildingNumber',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Address',
            'key': 'country',
            'label': 'Country',
            'name': 'country',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Address',
            'key': 'countryCode',
            'label': 'Country code',
            'name': 'countryCode',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Address',
            'key': 'direction',
            'label': 'Direction',
            'name': 'direction',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Address',
            'key': 'zip',
            'label': 'ZIP Code',
            'name': 'zip',
            'type': 'varchar',
            'fields': {
                'format': {
                    'type': 'text',
                    'label': 'Format:',
                    'value': '####'
                }
            },
            active: true
        },
        {
            'category': 'Custom',
            'key': 'custom',
            'label': 'Custom',
            'name': 'custom',
            'type': 'text',
            'fields': {
                'custom': {
                    'type': 'textarea',
                    'label': 'Custom:',
                    'value': `line.firstname + '.' + line.lastname + '@gmail.com';`
                }
            },
            active: true
        },
        {
            'category': 'Work',
            'key': 'companyName',
            'label': 'Company Name',
            'name': 'companyName',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Work',
            'key': 'jobArea',
            'label': 'Job Area',
            'name': 'jobArea',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Work',
            'key': 'jobDescriptor',
            'label': 'Job Descriptor',
            'name': 'jobDescriptor',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Work',
            'key': 'jobTitle',
            'label': 'Job Title',
            'name': 'jobTitle',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Work',
            'key': 'jobType',
            'label': 'Job Type',
            'name': 'jobType',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Commerce',
            'key': 'department',
            'label': 'Department',
            'name': 'department',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Commerce',
            'key': 'price',
            'label': 'Price',
            'name': 'price',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Commerce',
            'key': 'product',
            'label': 'Product',
            'name': 'product',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Commerce',
            'key': 'productAdjective',
            'label': 'Product Adjective',
            'name': 'productAdjective',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Commerce',
            'key': 'productDescription',
            'label': 'Product Description',
            'name': 'productDescription',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Commerce',
            'key': 'productMaterial',
            'label': 'Product Material',
            'name': 'productMaterial',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Commerce',
            'key': 'productName',
            'label': 'Product Name',
            'name': 'productName',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Vehicle',
            'key': 'vehicle.color',
            'label': 'Color',
            'name': 'vehicle.color',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Vehicle',
            'key': 'vehicle.fuel',
            'label': 'Fuel',
            'name': 'vehicle.fuel',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Vehicle',
            'key': 'vehicle.manufacturer',
            'label': 'Manufacturer',
            'name': 'vehicle.manufacturer',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Vehicle',
            'key': 'vehicle.model',
            'label': 'Model',
            'name': 'vehicle.model',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Vehicle',
            'key': 'vehicle.type',
            'label': 'Type',
            'name': 'vehicle.type',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Vehicle',
            'key': 'vehicle.vehicle',
            'label': 'Vehicle',
            'name': 'vehicle.vehicle',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Vehicle',
            'key': 'vehicle.vin',
            'label': 'Vin',
            'name': 'vehicle.vin',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Vehicle',
            'key': 'vehicle.vrm',
            'label': 'Vrm',
            'name': 'vehicle.vrm',
            'type': 'varchar',
            'fields': {},
            active: true
        },
        {
            'category': 'Vehicle',
            'key': 'vehicle.bicycle',
            'label': 'Bicycle',
            'name': 'vehicle.bicycle',
            'type': 'varchar',
            'fields': {},
            active: true
        },
    ];
};