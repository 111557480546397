import React from 'react';
import {GenerateContainer} from '../components/GenerateData/GenerateContainer';
import {useGenerate} from '../hooks/generate.hook';
import {useExport} from '../hooks/export.hook';
import {GenerateContext} from '../context/GenerateContext';
import {ExportContext} from '../context/ExportContext';
import Copyright from '../components/Copyright';
import GeneratorFileContent from '../components/GenerateData/Content';
import {Helmet} from 'react-helmet';
import content from '../config/content';

export const GeneratorPage = () => {

    return (
        <>
            <Helmet>
                <title>{content.data_page.title}</title>
                <link rel="canonical" href={content.data_page.canonical}/>
                <meta name="description" content={content.data_page.description}/>
                <meta name="keywords" content={content.data_page.keywords}/>
                <meta property="og:title" content={content.data_page.title}/>
                <meta property="og:description" content={content.data_page.description}/>
                <meta property="og:url" content={content.data_page.ogUrl}/>
                <meta property="og:image" content={content.data_page.ogImage}/>
                <meta name="designer" content={content.data_page.designer}/>
            </Helmet>

            <GenerateContext.Provider value={useGenerate()}>
                <ExportContext.Provider value={useExport()}>
                    <GenerateContainer/>
                    <GeneratorFileContent/>
                    <Copyright/>
                </ExportContext.Provider>
            </GenerateContext.Provider>
        </>
    );
};