import React, {forwardRef, useContext} from 'react';
import {GenerateContext} from '../../context/GenerateContext';
import AutosizeInput from './AutosizeInput';
import {PreloaderContext} from '../../context/PreloaderContext';
import DatePicker, {registerLocale} from 'react-datepicker';
import en from 'date-fns/locale/en-US';
import moment from 'moment';
import '../../assets/datapicker.scss';

registerLocale('en', en);

export const FieldSetting = ({field, indexItem, indexRow}) => {
    const generate = useContext(GenerateContext);
    const preloader = useContext(PreloaderContext);
    const {type, value, values, label, attr} = field;

    const onChange = (index, item, value) => {
        // console.log(value)
        let updatedRows = generate.rows.getRows.slice();
        const copyUpdatedRows = JSON.parse(JSON.stringify(updatedRows[index]));

        if (copyUpdatedRows.fields[item].type === 'number') {
            copyUpdatedRows.fields[item].value = parseInt(value);
        } else {
            copyUpdatedRows.fields[item].value = value;
        }

        updatedRows[index] = copyUpdatedRows;
        generate.rows.update(updatedRows);
    };


    switch (type) {
        case 'date':
        case 'datetime-local':
            const selected = value ? moment(value).toDate() : null;
            const ExampleCustomInput = forwardRef(({value, onClick}, ref) => (
                <span className="react-datepicker-span" onClick={onClick} ref={ref}>
                    {value ? value : 'dd . mm . yyyy'}
                </span>
            ));
            return (
                <div key={indexRow} className="input-field">
                    <label>{label}</label>
                    <DatePicker
                        {...attr}
                        type={type}
                        selected={selected}
                        onChange={date => onChange(indexRow, indexItem, date)}
                        dateFormat="dd.MM.yyyy"
                        locale="en"
                        placeholder={label}
                        disabled={preloader.preloader}
                        customInput={<ExampleCustomInput/>}
                        aria-label={label}
                    />
                </div>
            );
        case 'email':
        case 'number':
        case 'range':
        case 'search':
        case 'tel':
        case 'time':
        case 'url':
        case 'month':
        case 'week':
        case 'text':
            return (
                <div key={indexRow} className="input-field">
                    <label>{label}</label>
                    <AutosizeInput
                        {...attr}
                        className="input-card-blue"
                        type={type}
                        value={value}
                        onChange={event => onChange(indexRow, indexItem, event.target.value)}
                        placeholder={label}
                        disabled={preloader.preloader}
                        aria-label={label}
                    />
                </div>
            );
        case 'textarea':
            return (
                <div key={indexRow} className="input-field input-field-textarea">
                    <textarea
                        rows={5}
                        className="materialize-textarea"
                        value={value}
                        onChange={event => onChange(indexRow, indexItem, event.target.value)}
                        placeholder={label}
                        disabled={preloader.preloader}
                        aria-label={label}
                    />
                </div>
            );
        case 'select':
            return (
                <div key={indexRow} className="input-field">
                    <label>{label}</label>
                    <select
                        className="browser-default form-select"
                        onChange={event => onChange(indexRow, indexItem, event.target.value)}
                        disabled={preloader.preloader}
                        aria-label={label}
                    >
                        {values.map((option, key) => {
                            return (
                                <option value={option.key}
                                        key={key}>{option.label}</option>
                            );
                        })}
                    </select>
                </div>
            );
        default:
            return (
                <div>
                </div>
            );
    }
};