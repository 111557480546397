import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import {GeneratorPage} from './pages/GeneratorPage';
import {PasswordPage} from './pages/PasswordPage';
import {HomePage} from './pages/HomePage';
import {NotFound} from './pages/NotFound';
import {AuthPage} from './pages/AuthPage';
import {AdminPassGenPage} from './pages/admin/PassGenPage';
import {AdminDataGenPage} from './pages/admin/DataGenPage';
import {AdminAIPage} from './pages/admin/AiPage';

export const useRoutes = (isAuthenticated, isAdmin) => (<Routes>
    <Route path="/" element={<HomePage/>}/>
    <Route path="/password-generator" element={<PasswordPage/>}/>
    <Route path="/data-generator" element={<GeneratorPage/>}/>

    {isAuthenticated ? (
        <>
            <Route path="/admin/pass_gen_logs" element={<AdminPassGenPage/>}></Route>
            <Route path="/admin/data_gen_logs" element={<AdminDataGenPage/>}></Route>
            <Route path="/admin/ai_logs" element={<AdminAIPage/>}></Route>
        </>
    ) : (
        <></>
    )}

    <Route path="/login" element={isAuthenticated ? <Navigate to="/admin/pass_gen_logs"/> : <AuthPage/>}></Route>

    <Route path="*" element={<NotFound/>}/>
</Routes>);