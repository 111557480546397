import {Button, Col, Form, Row} from 'react-bootstrap';
import Slider from 'rc-slider';
import React, {useContext, useEffect, useState} from 'react';
import {PasswordContext} from '../../context/PasswordContext';


export const ComponentSize = () => {
    const generate = useContext(PasswordContext);
    const [size, setSize] = useState(generate.setting.sizePassword);
    const min = 1;

    const changeSizeHandler = (e) => {
        let size = e.target.value;
        setSize(size);
        if (size >= min) {
            generate.setting.setSizePassword(size);
        }
    };
    const changeSizeCloseHandler = (e) => {
        if (e.target.value <= 1) {
            setSize(generate.setting.sizePassword);
        }
    };

    useEffect(() => {
        setSize(generate.setting.sizePassword);
    }, [generate.setting.sizePassword]);

    return (
        <Row className={`container-slider`}>
            <Col lg={2} md={3}>
                <div className={`password-length`}>
                    <label>Length:</label>
                    <Form.Control
                        type={`number`}
                        value={size}
                        onChange={changeSizeHandler}
                        onBlur={changeSizeCloseHandler}
                    />
                </div>
            </Col>
            <Col lg={8} md={7}>
                <Slider
                    className={`slider-password-marks`}
                    value={generate.setting.sizePassword}
                    min={generate.setting.minSizeMarker}
                    max={generate.setting.maxSizeMarker}
                    step={1}
                    onChange={(e) => generate.setting.setSizePassword(e)}
                    marks={generate.setting.sizeMarks}
                    style={{width: '100%'}}
                />
            </Col>
            <Col md={2} className={`password-slider-btn`}>
                <Button
                    onClick={() => generate.buttonHandler('prev', generate.setting.sizePassword)}>
                    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.80441 0.195262C5.06476 0.455612 5.06476 0.877722 4.80441 1.13807L1.94248 4L4.80441 6.86193C5.06476 7.12228 5.06476 7.54439 4.80441 7.80474C4.54406 8.06509 4.12195 8.06509 3.8616 7.80474L0.52827 4.4714C0.26792 4.21106 0.26792 3.78894 0.52827 3.5286L3.8616 0.195262C4.12195 -0.0650874 4.54406 -0.0650874 4.80441 0.195262Z"
                            fill="white"/>
                        <path
                            d="M8.80441 0.195262C9.06476 0.455612 9.06476 0.877722 8.80441 1.13807L5.94248 4L8.80441 6.86193C9.06476 7.12228 9.06476 7.54439 8.80441 7.80474C8.54406 8.06509 8.12195 8.06509 7.8616 7.80474L4.52827 4.4714C4.26792 4.21106 4.26792 3.78894 4.52827 3.5286L7.8616 0.195262C8.12195 -0.0650874 8.54406 -0.0650874 8.80441 0.195262Z"
                            fill="white"/>
                    </svg>
                </Button>
                <Button
                    onClick={() => generate.buttonHandler('next', generate.setting.sizePassword)}>
                    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.202772 0.195262C0.473135 -0.0650874 0.91148 -0.0650874 1.18184 0.195262L4.64338 3.5286C4.91374 3.78894 4.91374 4.21106 4.64338 4.4714L1.18184 7.80474C0.91148 8.06509 0.473135 8.06509 0.202772 7.80474C-0.0675908 7.54439 -0.0675908 7.12228 0.202772 6.86193L3.17478 4L0.202772 1.13807C-0.0675908 0.877722 -0.0675908 0.455612 0.202772 0.195262Z"
                            fill="white"/>
                        <path
                            d="M4.35662 0.195262C4.62698 -0.0650874 5.06533 -0.0650874 5.33569 0.195262L8.79723 3.5286C9.06759 3.78894 9.06759 4.21106 8.79723 4.4714L5.33569 7.80474C5.06533 8.06509 4.62698 8.06509 4.35662 7.80474C4.08626 7.54439 4.08626 7.12228 4.35662 6.86193L7.32862 4L4.35662 1.13807C4.08626 0.877722 4.08626 0.455612 4.35662 0.195262Z"
                            fill="white"/>
                    </svg>
                </Button>
            </Col>
        </Row>
    );
};