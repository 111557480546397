import React, {useEffect} from 'react';
import Copyright from '../components/Copyright';
import {Helmet} from 'react-helmet';
import content from '../config/content';
import {NavLink} from 'react-router-dom';
import {Col, Container, Row, Accordion} from 'react-bootstrap';

export const HomePage = () => {
    useEffect(() => {
        // 👇 add class to body element
        document.body.classList.add('home-page');

        return () => {
            // 👇️ removing classes from body element
            // when the component unmounts
            document.body.classList.remove('home-page');
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{content.home.title}</title>
                <link rel="canonical" href={content.home.canonical}/>
                <meta name="description" content={content.home.description}/>
                <meta property="og:title" content={content.home.title}/>
                <meta property="og:description" content={content.home.description}/>
                <meta property="og:url" content={content.home.ogUrl}/>
                <meta name="designer" content={content.home.designer}/>
            </Helmet>
            <section className="section-home">
                <Container>
                    <div className="section-home__title"><h1>Random Generator</h1></div>
                    <div className="section-home__description">Generate Realistic Datasets for Web Projects and Unique
                        Bulk Passwords Online
                    </div>
                    <div className="section-home__buttons-container">
                        <div className="section-home__buttons">
                            <NavLink to={`/data-generator`} className="btn btn-primary" end>Generate Data</NavLink>
                            <span>or</span>
                            <NavLink to={`/password-generator`} className="btn btn-primary" end>Generate
                                Passwords</NavLink>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="section-dataset">
                <Container>
                    <div className="section-dataset__container">
                        <div className="icon-block icon-block__1"><img src="/img/icon-1.png" alt={content.home.title}
                                                                       width={337} height={197}/></div>
                        <div className="icon-block icon-block__2"><img src="/img/icon-2.png" alt={content.home.title}
                                                                       width={521} height={222}/></div>
                        <Row>
                            <Col className="section-dataset__img-block">
                                <figure>
                                    <img src="/img/section-dataset-1.png" alt={content.home.title} width={1328}
                                         height={926}/>
                                </figure>
                            </Col>
                            <Col className="section-dataset__content-block">
                                <div className="section-dataset__title"><h2>Generate Unlimited Rows of Synthetic
                                    Data</h2></div>
                                <div className="section-dataset__image-mobile">
                                    <figure>
                                        <img src="/img/section-dataset-1.png" alt={content.home.title} width={1328}
                                             height={926}/>
                                    </figure>
                                </div>
                                <div className="section-dataset__description">
                                    <p>With RNDGen random data generator you won't need anymore to write code, connect
                                        apps to your storage or try to understand if a file with mock data suits your
                                        project. Everything is simple and now you can generate thousands of real-like
                                        data in a few clicks and in any format: Excel, CSV, XML, JSON and SQL. No
                                        Datasets and many data files anymore, just set whatever data and amount of rows
                                        you need and upload the file.</p>
                                </div>
                                <div className="section-dataset__buttons">
                                    <NavLink to={`/data-generator`} className="btn btn-primary btn-large" end>Generate
                                        Real-Like Data</NavLink>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className="section-passet">
                <Container>
                    <div className="section-passet__container">
                        <Row>
                            <Col className="section-passet__content-block">
                                <div className="section-passet__title"><h2>Unlimited Chapters, Unique and Bulk Password
                                    Generator</h2></div>
                                <div className="section-dataset__image-mobile">
                                    <figure>
                                        <img src="/img/section-passet-1.png" alt={content.home.title} width={1328}
                                             height={926}/>
                                    </figure>
                                </div>
                                <div className="section-passet__description">
                                    <p>Experience the power of our random password generator that swiftly creates a
                                        multiple of strong, random passwords with unlimited characters. Ideal solution
                                        for businesses and individuals alike. This tool is versatile, aiding in
                                        scenarios like securing multiple accounts or Wi-Fi networks, setting up various
                                        devices, or creating guest accounts in bulk or testing needs.</p>
                                </div>
                                <div className="section-passet__buttons">
                                    <NavLink to={`/password-generator`} className="btn btn-primary btn-large" end>Generate
                                        Passwords</NavLink>
                                </div>
                            </Col>
                            <Col className="section-passet__img-block">
                                <figure>
                                    <img src="/img/section-passet-1.png" alt={content.home.title} width={1328}
                                         height={926}/>
                                </figure>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className="section-about">
                <Container>
                    <div className="section-about__container">
                        <div className="section-about__content">
                            <div className="section-about__title"><h2>Random Generator by RNDGen</h2></div>
                            <div className="section-about__description">
                                <p>RNDGen random generator stands out in its ability to create high-quality, synthetic
                                    data without any complexities. Developed by a team of skilled developers, we created
                                    this random generator for our own use, so this tool includes everything we haven’t
                                    found in other random generators. We are committed to providing the most
                                    user-friendly and efficient data generation service on the market. With our
                                    easy-to-use interface, you can generate data easily and focus on what matters - your
                                    work.</p>
                                <p>Forget the hassle of writing code, connecting apps to your storage, interpreting if a
                                    file with mock data is suited for your project, or even dealing with logging in and
                                    a long registration process.</p>
                                <p>With RNDGen.com, generating real-like data is a breeze – just a few clicks online and
                                    you're done! Choose from multiple file formats such as Excel, CSV, JSON, and SQL and
                                    100+ data fields or even custom fields for any project requirements.</p>
                                <p>Start generating random data today with RNDGen, the leading random generator on the
                                    market!</p>
                            </div>
                            <div className="section-about__buttons-container">
                                <div className="section-about__buttons">
                                    <NavLink to={`/data-generator`} className="btn btn-primary" end>Generate
                                        Data</NavLink>
                                    <span>or</span>
                                    <NavLink to={`/password-generator`} className="btn btn-primary" end>Generate
                                        Passwords</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className="section-faq">
                <Container>
                    <div className="section-faq__container">
                        <Row>
                            <Col>
                                <div className="section-faq__icon">
                                    <img src="/img/faq.svg" alt={content.home.title} width={221} height={213}/>
                                </div>
                                <div className="section-faq__title">You ask – we answer.</div>
                                <div className="section-faq__description">All you want to know about the product.</div>
                            </Col>
                            <Col>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header><h2>What is the RNDGen Random Generator?</h2>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            RNDGen Random Generator is an innovative tool designed to produce random
                                            data for various purposes. Whether you need random numbers, strings, or
                                            complex patterns, RNDGen offers a versatile solution making it ideal for
                                            applications in developing, gaming, statistical sampling, and more.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><h2>Can I use it for commercial goals?</h2></Accordion.Header>
                                        <Accordion.Body>
                                            Absolutely, you can use RNDGen for commercial purposes. It is incredibly
                                            useful in software and application testing and for training machine learning
                                            and AI models, as you can create large datasets that mimic real-world data,
                                            enabling more effective and ethical model training. Whether you're
                                            developing software, working on AI projects, or need large volumes of data
                                            for any other commercial application, RNDGen is equipped to meet your needs.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header><h2>Is RNDGen free?</h2></Accordion.Header>
                                        <Accordion.Body>
                                            We are committed to ensuring that essential features of RNDGen remain
                                            accessible to everyone, and thus, a range of key functionalities will always
                                            be available for free. However, we also offer advanced options in our paid
                                            plan, designed to provide a more personalized and enriched experience such
                                            as Projects, Table Sets, Field Relationships, API, User Management and soon
                                            AI integration.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header><h2>How Does RNDGen Benefit Developers?</h2>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            RNDGen offers significant benefits to developers by providing synthetic and
                                            fake data for testing and development, ensuring privacy and security
                                            compliance, and aiding in performance benchmarking. It's ideal for
                                            identifying bugs, training AI models, and safely demonstrating software
                                            capabilities without using real user data.
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <Copyright/>
        </>
    );
};