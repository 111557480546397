import AdsComponent from '../Components/AdsComponent';

function Widget2() {
    return (
        <div className={`ads ads-2`}>
            <AdsComponent dataAdSlot="4850630402"/>
        </div>
    );
}


export default Widget2;