import {v4 as uuid} from 'uuid';
import {useCallback, useContext, useEffect, useState} from 'react';
import {templateConfig} from '../template/default.template';
import {CookieContext} from '../context/CookieContext';

const def = {
    rows: 1000,
};

export const useGenerate = () => {
    const cookie = useContext(CookieContext);
    const [rowCount, setRowCount] = useState(def.rows);
    const [rows, setRows] = useState([]);
    const [rowDefault, setRowDefault] = useState([]);
    const [rowData, setRowData] = useState(false);
    const template = templateConfig();
    const [categoriesTemplate, setCategoriesTemplate] = useState([]);
    const [language, setLanguage] = useState('en');
    const [aiRequest, setAiRequest] = useState(null);
    const [aiResponse, setAiResponse] = useState(null);

    useEffect(() => {
        if (cookie.global.has('dataGen')) {
            const {rows, setting} = cookie.global.get('dataGen');
            setRows(rows);
            setRowCount(setting.count);
        } else {
            setRows(
                JSON.parse(JSON.stringify(
                    template.slice().filter(item => item.default)
                )).map(item => {
                    item.id = uuid();
                    item.valid = true;
                    return item;
                }).sort((a, b) => a.order > b.order ? 1 : -1)
            );
        }

        setRowDefault(
            JSON.parse(JSON.stringify(
                template.slice().find(item => item.default)
            ))
        );
    }, []);
    useEffect(() => {
        let categories = [];
        categories['All'] = template;
        template
            .sort((a, b) => a.category > b.category ? 1 : -1)
            .forEach((temp, i) => {
                if (!categories[temp.category]) {
                    categories[temp.category] = [];
                }
                categories[temp.category].push(temp);
            });
        for (let category in categories) {
            if (Array.isArray(categories[category])) {
                categories[category].sort((a, b) => (a.name > b.name ? 1 : -1));
            }
        }
        setCategoriesTemplate(Object.entries(categories));

    }, []);


    const add = useCallback((rows, rowDefault) => {
        const copyRowDefault = JSON.parse(JSON.stringify(rowDefault));
        copyRowDefault.id = uuid();

        let i = false,
            index = 1;
        while (i === false) {
            let currentRows = rows.filter(item => item.name === copyRowDefault.name);
            i = !currentRows.length;

            if (!i) {
                let mix = copyRowDefault.name.split('_'),
                    lastMix = parseInt(mix[mix.length - 1]);

                if (mix.length === 1) {
                    copyRowDefault.name += `_${index}`;
                } else {
                    if (!isNaN(lastMix)) {
                        mix.pop();
                    }
                    copyRowDefault.name = mix.join('_') + '_' + index;
                }
            }

            if (index > 1000) {
                i = true;
            }
            index++;
        }

        setRows(() => [...rows, copyRowDefault]);
    }, []);


    const remove = useCallback((rows, index) => {
        setRows([...rows.slice(0, index), ...rows.slice(index + 1)]);
    }, []);

    const update = useCallback((rows) => {
        setRows(rows);
    }, []);

    const reset = useCallback(() => {
        setRows(
            JSON.parse(JSON.stringify(
                template.slice().filter(item => item.default)
            )).map(item => {
                item.id = uuid();
                item.valid = true;
                return item;
            }).sort((a, b) => a.order > b.order ? 1 : -1)
        );
        setRowCount(def.rows);
    }, []);

    const setRowsAi = useCallback(response => {
        let rowsTmp = [];
        response.forEach(item => {
            let templateRow = JSON.parse(JSON.stringify(template.slice().find(t => t.key === item.key)));
            templateRow.id = uuid();
            templateRow.valid = true;
            templateRow.name = item.value;
            rowsTmp.push(templateRow);
        });
        setRows(rowsTmp);
    });

    const dnd = useCallback((result, rows) => {
        if (!result.destination) {
            return false;
        }
        const {source, destination} = result;
        const copiedItems = [...rows];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setRows(copiedItems);
    }, []);

    const changeCount = useCallback(e => {
        setRowCount(e.target.value);
    }, []);

    return {
        getTemplate: template,
        getCategories: categoriesTemplate,
        rows: {
            getRows: rows,
            getCount: rowCount,
            getDefault: rowDefault,
            add,
            remove,
            update,
            reset,
            setRows,
            dnd,
            changeCount
        },
        demo: {
            rowData,
            setRowData
        },
        language,
        setLanguage,
        ai: {
            setRowsAi,
            setRequest: setAiRequest,
            setResponse: setAiResponse,
            request: aiRequest,
            response: aiResponse
        }
    };
};