import '../assets/copyright.scss';

function Copyright() {
    const year = new Date().getFullYear();
    return (
        <div className={`container-copyright`}>
            © {year} All rights reserved | <a id="wds-credential-link-webdesignsun"
                                              className="wds-credential-link-webdesignsun"
                                              href="https://www.webdesignsun.com"
                                              title="Designed and Developed with Soul and Care by Web Design Sun®️"
                                              rel="noreferrer"
                                              target="_blank">Web Design Sun</a>
        </div>
    );
}


export default Copyright;