import React, {useContext, useState} from 'react';
import {useHttp} from '../hooks/http.hook';
import {useMessage} from '../hooks/message.hook';
import {AuthContext} from '../context/AuthContext';
import {Helmet} from 'react-helmet';
import {Container, Stack} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

export const AuthPage = () => {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const message = useMessage();
    const {loading, request} = useHttp();
    const [form, setForm] = useState({
        email: '',
        password: ''
    });


    const changeHandler = event => {
        setForm({...form, [event.target.name]: event.target.value});
    };

    // const registerHandler = async () => {
    //   try {
    //     const data = await request('/api/auth/register', 'POST', { ...form });
    //     message(data.message);
    //   } catch (e) {
    //     message(e.message, 'error')
    //   }
    // };

    const loginHandler = async () => {
        try {
            const data = await request('/api/auth/login', 'POST', {...form});
            auth.login(data.token, data.userId, data.isadmin);
            navigate("/");
        } catch (e) {
            message(e.message, 'error');
        }
    };

    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>

            <Container fluid className={`container-color-1`}>
                <Container>
                    <Stack direction="horizontal" gap={3} className="w-100 generator-stack-top">
                        <div className="generator-stack-top-title">
                            <h1>Login</h1>
                        </div>
                    </Stack>
                </Container>
            </Container>
            <Container fluid className={`container-color-2 p-5`}>
                <Container>
                    <div className="row">
                        <div className="col-6 offset-3">
                            <div>
                                <div className="form-group mb-3">
                                    <input
                                        id="email"
                                        name="email"
                                        type="text"
                                        className="form-control"
                                        placeholder="E-mail"
                                        onChange={changeHandler}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        onChange={changeHandler}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn btn-primary"
                                    onClick={loginHandler}
                                    disabled={loading}>
                                    Login
                                </button>
                                {/*<button*/}
                                {/*  className="btn gray lighten-1 white-text"*/}
                                {/*  onClick={registerHandler}*/}
                                {/*  disabled={loading}*/}
                                {/*>*/}
                                {/*  Register*/}
                                {/*</button>*/}
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
        </>
    );
};