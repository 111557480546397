import {useCallback, useEffect, useState} from 'react';
import Cookies from 'universal-cookie';


export const useCookie = () => {
    const cookies = new Cookies();

    const [popupStatus, setPopupStatus] = useState(false);
    const [cookieStatus, setCookieStatus] = useState(false);

    useEffect(() => {
        if (cookies.get('cookieStatus')) {
            setPopupStatus(false);
            setCookieStatus(!!cookies.get('cookieStatus'));
        } else {
            setPopupStatus(true);
            setCookieStatus(false);
        }

    }, []);


    const acceptCookieHandler = useCallback(() => {
        cookies.set('cookieStatus', true);
        setPopupStatus(false);
        setCookieStatus(true);
    }, []);

    const declineCookieHandler = useCallback(() => {
        setPopupStatus(false);
        setCookieStatus(false);
    }, []);


    const closeCookieHandler = useCallback(() => {
        setPopupStatus(false);
        setCookieStatus(false);
    }, []);


    const hasCookie = useCallback((name = ``) => {
        return !!cookies.get(name);
    }, []);
    const getCookie = useCallback((name = ``) => {
        return cookies.get(name);
    }, []);
    const setCookie = useCallback((name = ``, data = {}) => {
        cookies.set(name, JSON.stringify(data));
    }, []);
    const removeCookie = useCallback((name = ``) => {
        cookies.remove(name);
    }, []);
    const getByKeyDef = useCallback((name = ``, def, key) => {
        const data = cookies.get(name);

        if (!data) return def;
        return data[key] ?? def;
    }, []);

    return {
        popupStatus,
        cookieStatus,
        acceptCookieHandler,
        declineCookieHandler,
        closeCookieHandler,
        global: {
            has: hasCookie,
            get: getCookie,
            set: setCookie,
            remove: removeCookie,
            getByKeyDef: getByKeyDef
        }
    };
};