const host = window.location.hostname;

const config = {
    password_page: {
        title: 'Password Generator | Random and Secure',
        description: 'RNDGen password generator lets you create secure passwords with one click based on the characteristics you set.',
        keywords: 'password generator, online password generator, secure password generator, strong password generator, random password generator, create password online',
        canonical: `https://${host}/password-generator`,
        ogUrl: `https://${host}/password-generator`,
        ogImage: `https://${host}/img/random-password-generator-rndgen.com.jpg`,
        designer: 'Designed with Soul and Care by Web Design Sun®️ | https://www.webdesignsun.com',
        content: {
            title: 'Password Generator'
        }
    },
    data_page: {
        title: 'Data Generator | CSV, SQL, JSON, XML, Excel',
        description: 'RNDGen random data generator is a free service that easily generates large amounts of randomized test data. 100+ types of dummy data templates.',
        keywords: 'random data generator, dummy data generator, CSV, SQL, JSON, XML, Excel, data generation, testing, research, software development, dataset generator, online data generator, name, email, location, address',
        canonical: `https://${host}/data-generator`,
        ogUrl: `https://${host}/data-generator`,
        ogImage: `https://${host}/img/random-data-generator-rndgen.com.jpg`,
        designer: 'Designed with Soul and Care by Web Design Sun®️ | https://www.webdesignsun.com',
        content: {
            title: 'Data Generator'
        }
    },
    home: {
        title: 'RNDGen Random Generator',
        description: 'Generate realistic datasets and bulk passwords effectively with RNDGen - an ideal online data generator solution for web projects, software testing, and more. Easy, fast, and free.',
        canonical: `https://${host}`,
        ogUrl: `https://${host}`,
        designer: 'Designed with Soul and Care by Web Design Sun®️ | https://www.webdesignsun.com',
        content: {
            title: 'RNDGen Random Generator'
        }
    }
};

export default config;