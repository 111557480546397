import {Button, Col, Form, InputGroup, Modal, Row} from 'react-bootstrap';
import {useGenerate} from '../hooks/generate.hook';
import React, {useEffect, useState} from 'react';
import '../assets/popup_type.scss';

export const ChangeTypePopup = prop => {

    const {indexRow, closeHandler, changeTypeHandler} = prop;
    const {getCategories} = useGenerate();
    const [categoriesCurrent, setCategoriesCurrent] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setCategoriesCurrent(JSON.parse(JSON.stringify(getCategories)));
    }, [getCategories]);

    useEffect(() => {
        let catTemp = JSON.parse(JSON.stringify(getCategories));
        catTemp = catTemp.map(cat => {
            let catTemp = cat;
            catTemp[1] = catTemp[1].filter(item => (item.label).toLowerCase().includes(search));
            return catTemp;
        });
        setCategoriesCurrent(JSON.parse(JSON.stringify(catTemp)));
    }, [search]);

    const TabMenu = () => {
        return (
            <Col xs={3}>
                {categoriesCurrent
                    .map((element, i) => {
                        const className = `change-type-menu-item ${activeTab === i ? 'active' : ''}`;
                        return (
                            <div
                                key={i}
                                className={className}
                                onClick={() => setActiveTab(i)}
                            >{element[0]} ({element[1].length})</div>
                        );
                    })}
            </Col>
        );
    };

    const clickTypeHandler = key => {
        changeTypeHandler(key, indexRow);
        closeHandler(false);
    };

    const TabContent = () => {
        return (
            <Col xs={9}>
                {categoriesCurrent.map((element, i) => {
                    const className = `change-type-item ${activeTab === i ? 'active' : ''}`;
                    return (
                        <Row key={i} className={className}>
                            {
                                element[1].length
                                    ? element[1].map((item, key) => {
                                        return (
                                            <Col key={key} className={`container-btn-type`}>
                                                <Button
                                                    className={`btn-type-style`}
                                                    onClick={() => clickTypeHandler(item.key)}
                                                >
                                                    {item.label}
                                                </Button>
                                            </Col>
                                        );
                                    })
                                    : <Col>No records!</Col>
                            }
                        </Row>
                    );
                })}
            </Col>
        );
    };

    return (
        <>
            <Modal
                size="lg"
                show={true}
                onHide={() => closeHandler(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Classification Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon1">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2ZM0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9Z"
                                            fill="#98A2B3"/>
                                        <path
                                            d="M13.9429 13.9429C14.3334 13.5524 14.9666 13.5524 15.3571 13.9429L19.7071 18.2929C20.0976 18.6834 20.0976 19.3166 19.7071 19.7071C19.3166 20.0976 18.6834 20.0976 18.2929 19.7071L13.9429 15.3571C13.5524 14.9666 13.5524 14.3334 13.9429 13.9429Z"
                                            fill="#98A2B3"/>
                                    </svg>
                                </InputGroup.Text>
                                <Form.Control
                                    type={`search`}
                                    onChange={e => setSearch(e.target.value)}
                                    value={search}
                                    placeholder={`Search`}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <TabMenu/>
                        <TabContent/>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};