import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap';
import React, {useState} from 'react';
import {TextareaAutosize} from '@mui/material';
import {Copied} from '../Icons';


export const ComponentResult = (prop) => {
    const {result} = prop;
    const [copy, setCopy] = useState(false);

    const copyHandler = () => {
        if (typeof result === `string`) {
            navigator.clipboard.writeText(result);
        } else {
            navigator.clipboard.writeText(result.join('\n'));
        }
        setCopy(true);
    };

    if (typeof result === `string` || !result) {
        return (
            <>
                <Row>
                    <Col md={{offset: 3, span: 6}}>
                        <div className={`result-string`}>
                            <label>Password</label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Password"
                                    defaultValue={result}
                                />
                                <Button
                                    variant={`outline-primary`}
                                    onClick={copyHandler}
                                    className={copy ? `btn-copied` : ``}
                                    disabled={!result}
                                >
                                    Copy
                                    <span><Copied/> Copied</span>
                                </Button>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
            </>
        );
    } else {
        return (
            <>
                <Row>
                    <Col md={{offset: 3, span: 6}}>
                        <div className={`result-string`}>
                            <label>Password</label>
                            <InputGroup className="mb-3">
                                <TextareaAutosize
                                    defaultValue={result.join('\n\n')}
                                    placeholder="Password"
                                    wrap={`off`}
                                    maxRows={12}
                                />
                                <Button
                                    variant={`outline-primary`}
                                    onClick={copyHandler}
                                    className={copy ? `btn-copied` : ``}
                                    disabled={!result}
                                >
                                    Copy
                                    <span><Copied/> Copied</span>
                                </Button>
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }


};