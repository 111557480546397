import {useCallback} from 'react';
import {toast} from 'react-toastify';

export const useMessage = () => {
    return useCallback((text, type = null) => {
        if (text) {
            switch (type) {
                case 'error':
                    toast.error(text);
                    break;
                case 'warning':
                    toast.warning(text);
                    break;
                case 'info':
                    toast.info(text);
                    break;
                case 'success':
                    toast.success(text);
                    break;
                default:
                    toast(text);
            }
        }
    }, []);
};