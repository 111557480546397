import React, {useEffect, useState, useContext, useRef} from 'react';
import {GenerateContext} from '../../context/GenerateContext';
import {ExportContext} from '../../context/ExportContext';
import {Button, Modal, Stack, Tab, Table, Tabs} from 'react-bootstrap';
import {MenuIcon, GridIcon, FullScreenIcon} from '../Icons';
import {PreloaderContext} from '../../context/PreloaderContext';
import {useOverlayScrollbars} from 'overlayscrollbars-react';

import '../../assets/preview.scss';
import 'overlayscrollbars/overlayscrollbars.css';

export const GeneratePreview = () => {
    const ref = useRef();
    const [initialize] = useOverlayScrollbars();

    useEffect(() => {
        initialize(ref.current);
    }, [initialize]);
    const generate = useContext(GenerateContext);
    const preloader = useContext(PreloaderContext);
    const exp = useContext(ExportContext);
    const [demoData, setDemoData] = useState('');
    const [demoStructureData, setDemoStructureData] = useState('');
    const [previewTab, setPreviewTab] = useState(0);
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        fetchPreviewHandler().then();
    }, [generate.rows.getRows, exp.setting.getExtension, exp.counter.get, generate.language]);

    const fetchPreviewHandler = async () => {
        console.log('previews after', generate.rows.getRows);
        const data = await exp.fetchHandler({
            rows: generate.rows.getRows,
            count: 10,
            extension: exp.setting.getExtension,
            setting: exp.setting.getExportSetting,
            preview: true,
            language: generate.language
        });

        setDemoData(data.export);
        setDemoStructureData(data.preview);

        const demo = constructDemoRowPreview(data.preview[1]);
        generate.demo.setRowData(demo);

    };

    const constructDemoRowPreview = (data) => {
        let result = [];
        let index = 0;
        generate.rows.getRows.forEach(i => {
            let value = '';

            if (i.active) {
                value = data[index];
                index++;
            }

            result.push(value);
        });

        return result;
    };

    const limit = (text, limit, ending = `...`) => {
        if (limit !== parseInt(limit) || limit <= 0) return text;
        if (text.length <= limit) return text;

        if (typeof text != 'string') return text;

        text = text.slice(0, limit);
        let lastSpace = text.lastIndexOf(' ');
        text = text.substr(0, lastSpace);
        return text + ending;
    };

    const FirstContent = () => {
        return (
            <pre className={`area-group-preview`} ref={ref}>{demoData}</pre>
        );
    };

    const LastContent = () => {
        const [header] = demoStructureData;
        const body = demoStructureData.slice(1);
        return (
            <Table responsive className={`table-preview`}>
                <thead>
                <tr className={`table-active`}>
                    {header.map((item, index) => {
                        return (
                            <th key={index}>
                                {limit(item, 37)}
                            </th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                {body.map((row, index) => {
                    return (
                        <tr key={index}>
                            {row.map((item, index_1) => {
                                return (
                                    <td key={index_1}>
                                        {limit(item, 37)}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </Table>
        );
    };

    const TabContent = ({previewTab}) => {
        switch (previewTab) {
            case 1:
                return <LastContent/>;
            default:
                return <FirstContent/>;
        }
    };


    return (
        <>
            <div className={`container-title`}>
                Preview
            </div>
            <div className={`container-preview d-flex flex-column`}>
                <Stack direction="horizontal" gap={2} className="w-100 mb-2">
                    <div className="ms-auto d-flex  stack-preview">
                        <div>
                            <Button
                                variant="tab"
                                className={previewTab === 0 ? 'preview-item-active' : ''}
                                onClick={() => setPreviewTab(0)}
                                disabled={preloader.preloader}
                            >
                                <MenuIcon/> Raw
                            </Button>
                        </div>
                        <div>
                            <Button
                                variant="tab"
                                className={previewTab === 1 ? 'preview-item-active' : ''}
                                onClick={() => setPreviewTab(1)}
                                disabled={preloader.preloader}
                            >
                                <GridIcon/> Table
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Button
                            variant="secondary"
                            aria-label={`Button full size`}
                            title={`Button full size`}
                            className={`btn-full-size`}
                            onClick={() => setModalShow(true)}
                            disabled={preloader.preloader}
                        >
                            <FullScreenIcon/>
                        </Button>
                    </div>
                </Stack>
                <TabContent
                    previewTab={previewTab}
                />

            </div>

            <Modal
                fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton/>
                <Modal.Body>
                    <div className="modal-body-container">
                        <div className={`modal-title-any`}>Preview</div>
                        <Tabs
                            defaultActiveKey="profile"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={previewTab}
                            onSelect={k => setPreviewTab(parseInt(k))}
                        >
                            <Tab eventKey={0} title={<><MenuIcon/> Raw</>}>
                                <FirstContent/>
                            </Tab>
                            <Tab eventKey={1} title={<><GridIcon/> Table</>}>
                                <LastContent/>
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};