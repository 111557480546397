import React, {useContext, useEffect, useState} from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {GenerateContext} from '../../context/GenerateContext';
import {PreloaderContext} from '../../context/PreloaderContext';
import {ExportContext} from '../../context/ExportContext';
import {CookieContext} from '../../context/CookieContext';
import {GenerateRow} from './GenerateRow';
import {GeneratePreview} from './GeneratePreview';
import {exportFormatTemplate} from '../../template/export.template';
import {languageFormatTemplate} from '../../template/language.template';
import {ExportFieldSetting} from '../_fields/field.export.setting.component';
import LinearProgress from '@mui/material/LinearProgress';

import {Row, Col, Stack, Form, Button, Container} from 'react-bootstrap';
import {PlusIcon, ResetIcon, SaveIcon} from '../Icons';
import '../../assets/table.scss';
import content from '../../config/content';
import {GenerateAI} from './GenerateAI';

export const GenerateContainer = () => {
    const generate = useContext(GenerateContext);
    const cookie = useContext(CookieContext);
    const exp = useContext(ExportContext);
    const preloader = useContext(PreloaderContext);
    const exportTemplate = exportFormatTemplate();
    const languageTemplate = languageFormatTemplate();
    const [isLoad, setLoad] = useState(false);
    const [saveCache, setSaveCache] = useState(false);

    useEffect(() => {
        setSaveCache(cookie.global.has(`dataGen`));
    }, []);

    useEffect(() => {
        if (!saveCache) {
            cookie.global.remove(`dataGen`);
        }
        if (isLoad) {
            saveCacheHandler();
        }
        setLoad(true);
    }, [saveCache]);

    useEffect(() => {
        if (generate.rows.getRows.length) {
            saveCacheHandler();
        }
    }, [
        generate.rows.getRows,
        exp.setting.getName,
        exp.setting.getExtension,
        exp.setting.getExportSetting.fields,
        generate.rows.getCount,
        exp.setting.packStatus,
        generate.language
    ]);

    const exportClick = async () => {
        preloader.showPreloader();

        saveCacheHandler();

        const requestData = {
            rows: generate.rows.getRows,
            count: generate.rows.getCount,
            extension: exp.setting.getExtension,
            setting: exp.setting.getExportSetting,
            language: generate.language,
            ai_request: generate.ai.request,
            ai_result: generate.ai.response
        };

        const data = await exp.fetchHandler(requestData);

        exp.exportHandler(data.export, exp.setting.getName, exp.setting.getExtension, exp.setting.packStatus);

        preloader.hidePreloader();
    };

    const saveCacheHandler = () => {
        if (saveCache) {
            cookie.global.set(`dataGen`, {
                setting: {
                    name: exp.setting.getName,
                    extension: exp.setting.getExtension,
                    fields: exp.setting.getExportSetting,
                    count: generate.rows.getCount,
                    zip: exp.setting.packStatus
                },
                rows: generate.rows.getRows,
            });
        }
    };

    const saveCacheClickHandler = () => {
        setSaveCache(!saveCache);
    };

    const handleChange = event => {
        exp.setting.setPackStatus(event.target.checked);
        document.activeElement.blur();
    };

    return (
        <div className={preloader.preloader ? 'preloader-active-container' : ''}>
            <Container fluid className={`container-color-1`}>
                <Container>
                    <Stack direction="horizontal" gap={3} className="w-100 generator-stack-top">
                        <div className="generator-stack-top-title">
                            <h1>{content.data_page.content.title}</h1>
                        </div>
                        <div className={`ms-auto generator-stack-top-btn`}>
                            <Button variant="primary"
                                    onClick={generate.rows.reset}
                                    disabled={preloader.preloader}
                            >
                                <ResetIcon/> Reset All
                            </Button>
                        </div>
                        <div className={`generator-stack-top-btn`}>
                            <Form.Check
                                type={`checkbox`}
                                className={`switch-new`}
                                id={`save-in-cache`}
                                disabled={!cookie.cookieStatus || preloader.preloader}
                            >
                                <Form.Check.Input
                                    type={`checkbox`}
                                    checked={saveCache}
                                    onChange={saveCacheClickHandler}
                                    disabled={!cookie.cookieStatus || preloader.preloader}
                                />
                                <Form.Check.Label><span>Save  in Cache</span></Form.Check.Label>
                            </Form.Check>
                            {/*<Button*/}
                            {/*    variant="outline-primary"*/}
                            {/*    onClick={() => generate.rows.save(generate.rows.getRows)}*/}
                            {/*    disabled={!cookie.cookieStatus || preloader.preloader}*/}
                            {/*>*/}
                            {/*    <PanelIcon/> Save Fields in Browser Cache {!!cookie.cookieStatus}*/}
                            {/*</Button>*/}
                        </div>
                    </Stack>
                </Container>
            </Container>

            <GenerateAI/>
            <Container fluid className={`container-color-1`}>
                <Container>
                    <Row>
                        <Col>
                            <div className="table-pane">
                                <DragDropContext onDragEnd={result => generate.rows.dnd(result, generate.rows.getRows)}>
                                    <div className={`dd-content`}>
                                        <Droppable droppableId={`aac96e2f-3125-4e9c-a347-630041fe60cc`}>
                                            {(provider) => {
                                                return (
                                                    <div className="flex-table"
                                                         {...provider.droppableProps}
                                                         ref={provider.innerRef}
                                                         style={{
                                                             ...provider.droppableProps.style
                                                         }}
                                                    >
                                                        <div className="table-flex-header">
                                                            <div className="table-flex-col"/>
                                                            <div className="table-flex-col">Field Name</div>
                                                            <div className="table-flex-col">Type</div>
                                                            <div className="table-flex-col">Example</div>
                                                            <div
                                                                className="table-flex-col table-flex-setting">Settings
                                                            </div>
                                                            <div className="table-flex-col">Options</div>
                                                        </div>
                                                        {generate.rows.getRows.map((row, i) => {
                                                            return (
                                                                <GenerateRow
                                                                    row={row}
                                                                    indexRow={i}
                                                                    key={i}
                                                                />
                                                            );
                                                        })}
                                                        {provider.placeholder}
                                                    </div>
                                                );
                                            }}
                                        </Droppable>
                                    </div>
                                </DragDropContext>

                                <Button
                                    variant="primary"
                                    onClick={() => generate.rows.add(generate.rows.getRows, generate.rows.getDefault)}
                                    disabled={preloader.preloader}
                                >
                                    <PlusIcon/> Add Row
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="container-color-1 container-preview-gb">
                <Container className={`container-preview-setting`}>
                    <Row className={`preview-row`}>
                        <Col lg={7} md={7} className="d-flex flex-column preview-col">
                            <GeneratePreview/>
                        </Col>
                        <Col md={5} className="d-flex flex-column preview-col">
                            <div className={`container-title`}>
                                Settings
                            </div>
                            <div className={`container-preview `}>
                                <div className={`action-fields ${exp.setting.getExtension + '_container_setting'}`}>
                                    <Form.Group className="action-field">
                                        <Form.Label
                                            disabled={preloader.preloader}>Template Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="My_template"
                                            value={exp.setting.getName}
                                            onChange={e => exp.setting.setName(e.target.value)}
                                            disabled={preloader.preloader}
                                            aria-label={`Template Name`}
                                        />
                                    </Form.Group>
                                    <Form.Group className="action-field">
                                        <Form.Label>Language</Form.Label>
                                        <Form.Select
                                            defaultValue={generate.language}
                                            onChange={e => generate.setLanguage(e.target.value)}
                                            key={generate.language}
                                            disabled={preloader.preloader}
                                            aria-label={`Language`}
                                        >
                                            {languageTemplate.map(r => {
                                                return (
                                                    <option value={r.code} key={r.code}>{r.name}</option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="action-field">
                                        <Form.Label>Format</Form.Label>
                                        <Form.Select
                                            defaultValue={exp.setting.getExtension}
                                            onChange={e => exp.setting.changeExportSetting(e.target.value)}
                                            key={exp.setting.getExtension}
                                            disabled={preloader.preloader}
                                            aria-label={`Format`}
                                        >
                                            {exportTemplate.map(r => {
                                                return (
                                                    <option value={r.key} key={r.key}>{r.label.toUpperCase()}</option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                    {
                                        Object.entries(exp.setting.getExportSetting.fields).map(([key, item], i) => {
                                            return (
                                                <ExportFieldSetting
                                                    field={item}
                                                    index={key}
                                                    key={key}
                                                    handler={saveCacheHandler}
                                                />
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="container-color-2 pt-md-5 pb-5">
                <Container>
                    <Row className="justify-content-center mb-md-5 container-bottom-gen">
                        <Col xs lg="2">
                            <Form.Group>
                                <Form.Label>Fields</Form.Label>
                                <Form.Control
                                    type="number"
                                    aria-label={`Field count rows`}
                                    value={generate.rows.getCount}
                                    onChange={generate.rows.changeCount}
                                    disabled={preloader.preloader}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="auto" className="d-flex align-content-md-end flex-wrap">
                            <Form.Group>
                                <Form.Check
                                    inline
                                    label="Archive to ZIP"
                                    name="archive"
                                    type="checkbox"
                                    id={`inline-checkbox-archive`}
                                    checked={exp.setting.packStatus}
                                    onChange={handleChange}
                                    disabled={preloader.preloader}
                                />
                            </Form.Group>
                        </Col>
                        <Col md="auto" className="line-horizontal"/>
                        <Col xs lg="3" className="d-flex align-content-md-end flex-wrap">
                            <div className={`position-relative`}>
                                <Button
                                    variant="primary" size="lg"
                                    className={`btn-export-style`}
                                    onClick={exportClick}
                                    disabled={preloader.preloader || !generate.rows.getRows.length}
                                >
                                    <SaveIcon/> Download Data File
                                </Button>
                                <LinearProgress
                                    className={`mt-3 progress-export ${preloader.preloader ? 'progress-export-show' : ''}`}
                                    title={`Progress bar generate`} aria-label={`Progress bar generate`}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
};