import React, {useContext} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import {PreloaderContext} from '../../context/PreloaderContext';
import {Error} from '../Icons';

export const FieldTitle = (prop) => {
    const {value, onChange, indexRow, invalid} = prop;
    const preloader = useContext(PreloaderContext);

    return (
        <InputGroup hasValidation>
            <Form.Control
                type="text"
                data-index={indexRow}
                onChange={onChange}
                value={value}
                disabled={preloader.preloader}
                isInvalid={invalid}
                aria-label={value}
            />
            <Form.Control.Feedback type="invalid">
                <Error/> Error
            </Form.Control.Feedback>
        </InputGroup>
    );
};