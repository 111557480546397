import {createContext} from 'react';

function noop() {
}

export const GenerateContext = createContext({
    getTemplate: [],
    getCategories: [],
    rows: {
        getRows: [],
        getCount: 1000,
        getDefault: [],
        add: noop,
        remove: noop,
        update: noop,
        reset: noop,
        setRows: noop,
        dnd: noop,
        changeCount: noop,
    },
    file: {
        getName: 'generate',
        setName: noop,
        getExtension: 'csv',
        getExportSetting: {},
        changeExportSetting: noop,
        updateExportSetting: noop
    },
    demo: {
        rowData: '',
        setRowData: noop
    },
    language: "en",
    setLanguage: noop,
    ai: {
        setRowsAi: noop,
        setRequest: noop,
        setResponse: noop,
        request: null,
        response: null
    }
});