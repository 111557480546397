import AdsComponent from '../Components/AdsComponent';

function Widget1() {
    return (
        <div className={`ads ads-1`}>
            <AdsComponent dataAdSlot="9465195663"/>
        </div>
    );
}


export default Widget1;