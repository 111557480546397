import {createContext} from 'react';

function noop() {
}

export const PasswordContext = createContext({
    generate: noop,
    generateMultiple: noop,
    setting: {
        sizePassword: 84,
        setSizePassword: noop,
        minSizeMarker: 4,
        setMinSizeMarker: noop,
        maxSizeMarker: 4,
        setMaxSizeMarker: noop,
        sizeMarks: {},
    },
    fetchLogHandler: noop,
});