import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {useRoutes} from './routes';
import {AuthContext} from './context/AuthContext';
import {PreloaderContext} from './context/PreloaderContext';
import {CookieContext} from './context/CookieContext';
import {useAuth} from './hooks/auth.hook';
import {usePreloader} from './hooks/preloader.hook';
import {useCookie} from './hooks/cookie.hook';
import {Navbar} from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/poppins';
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/500.css"; // Specify weight
import "@fontsource/poppins/600.css"; // Specify weight
import "@fontsource/poppins/700.css"; // Specify weight
import "@fontsource/poppins/800.css"; // Specify weight
import './assets/general.scss';
import 'react-toastify/dist/ReactToastify.css';
import Cookie from './components/Cookie';
import {ToastContainer} from 'react-toastify';
import {Feedback} from "./components/Feedback";
import ScrollToTop from "./components/ScrollToTop";


// { isAuthenticated && <Navbar/> }
function App() {
    const {token, login, logout, userId, isAdmin} = useAuth();
    const isAuthenticated = !!token;
    const routes = useRoutes(isAuthenticated);

    return (
        <>
            <PreloaderContext.Provider value={usePreloader()}>
                <CookieContext.Provider value={useCookie()}>
                    <AuthContext.Provider value={{
                        token, login, logout, userId, isAuthenticated, isAdmin
                    }}>
                        <Router>
                            <ScrollToTop/>
                            {<Navbar/>}
                            {routes}
                        </Router>
                    </AuthContext.Provider>
                    <Cookie/>
                </CookieContext.Provider>
            </PreloaderContext.Provider>
            <ToastContainer/>
            <Feedback/>
        </>
    );
}

export default App;
