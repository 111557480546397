import AdsComponent from '../Components/AdsComponent';

function Widget3() {
    return (
        <div className={`ads ads-3`}>
            <AdsComponent dataAdSlot="5780568697"/>
        </div>
    );
}


export default Widget3;