import {Container} from 'react-bootstrap';
import '../../assets/content.scss';
import Widget1 from '../Widgets/Widget_1';

function GeneratorFileContent() {
    return (
        <Container fluid className={`container-generator-file`}>
            <Container className={`container-generator-file-item`}>
                <Widget1/>
                <h2>Random Data Generator</h2>
                <p>
                    RNDGen <b>Random Data Generator</b> is a free user-friendly tool for generate test data. The data
                    creator uses
                    an existing data model and customizes it to <b>create a mock data</b> table structure for your
                    needs. Random
                    Data Generator also known like json generator, dummy data generator, csv generator, sql dummy or
                    mock data
                    generator.
                </p>
                <p>
                    Data Generator by RNDGen allows you to easily create <b>dummy data for tests</b> that are
                    representative of
                    real-world scenarios, with the ability to select from a <b>wide range of fake data
                    details</b> fields
                    including name, email, location, address, zip and vin codes and many others.
                </p>
                <p>
                    You can customize generated dummy data to meet your specific needs. With just a few clicks, you can
                    quickly <b>generate thousands of fake data rows</b> in different formats, including <b>CSV, SQL,
                    JSON, XML,
                    Excel</b>, making RNDGen the ultimate tool for all your data generation needs instead of standart
                    mock
                    datasets.
                </p>
                <p>
                    Try <a href="https://www.rndgen.com/">Password Generator</a>
                </p>
                <br/>
                <br/>
                <h2>How to Use Dummy Data Generator</h2>
                <h3>Step 1: Setting Up Your Mock Data Fields</h3>
                <ul>
                    <li><b>Specify the number of rows:</b> Choose the number of fake data rows you want to generate.
                    </li>
                    <li><b>Choose the data fields you need:</b> Select the fields you need to generate dataset from a
                        list.
                    </li>
                    <li><b>Customize test data fields:</b> Customize the settings for each field, such as the number of
                        characters
                        or format of the mock data.
                    </li>
                    <li><b>Set the order of fields:</b> Choose the order in which you want the dummy data in the
                        generated
                        database.
                    </li>
                    <li><b>Disable fields:</b> If you don't want to include a certain field in your generated mock
                        dataset, you
                        can easily disable it by clicking on the toggle switch.
                    </li>
                </ul>
                <p>
                    {/*<picture>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/1.webp'} type="image/webp"/>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/1.jpg'} type="image/jpeg"/>*/}
                    {/*</picture>*/}
                    {/*<img src={process.env.PUBLIC_URL + '/img/1.jpg'} alt="Step1" width={970} height={118}/>*/}

                    <picture>
                        <source srcSet={process.env.PUBLIC_URL + '/img/1_mob.jpg'} media="(max-width: 1023px)"
                                width={781}
                                height={210}/>
                        <source srcSet={process.env.PUBLIC_URL + '/img/1.jpg'} media="(min-width: 1024px)" width={970}
                                height={117}/>
                        <img src={process.env.PUBLIC_URL + '/img/1.jpg'} alt="Setting Up Your Mock Data Fields"
                             width={970}
                             height={117}/>
                    </picture>
                    {/*<picture>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/2.webp'} type="image/webp"/>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/2.jpg'} type="image/jpeg"/>*/}
                    {/*</picture>*/}
                    {/*<img src={process.env.PUBLIC_URL + '/img/2.jpg'} alt="Step2" width={970} height={435}/>*/}

                    <picture>
                        <source srcSet={process.env.PUBLIC_URL + '/img/2_mob.jpg'} media="(max-width: 1023px)"
                                width={778}
                                height={761}/>
                        <source srcSet={process.env.PUBLIC_URL + '/img/2.jpg'} media="(min-width: 1024px)" width={970}
                                height={433}/>
                        <img src={process.env.PUBLIC_URL + '/img/2.jpg'} alt="Setting Up Your Mock Data Fields"
                             width={970}
                             height={433}/>
                    </picture>
                </p>
                <h3>Step 2. Preview and Settings of Generated Data</h3>
                <ul>
                    <li><b>Preview:</b> the first 10 rows of your generated dataset.</li>
                    <li><b>Format:</b> select the desired file format, such as CSV, SQL, JSON, XML or Excel.</li>
                    <li><b>Settings:</b> set any additional file settings specific to the chosen format, such as
                        Template Name or
                        Delimiter.
                    </li>
                </ul>
                <p>
                    {/*<picture>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/3.webp'} type="image/webp"/>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/3.jpg'} type="image/jpeg"/>*/}
                    {/*</picture>*/}
                    <picture>
                        <source srcSet={process.env.PUBLIC_URL + '/img/3_mob.jpg'} media="(max-width: 1023px)"
                                width={511}
                                height={1000}/>
                        <source srcSet={process.env.PUBLIC_URL + '/img/3.jpg'} media="(min-width: 1024px)" width={970}
                                height={441}/>
                        <img src={process.env.PUBLIC_URL + '/img/3.jpg'} alt="Preview and Settings of Generated Data"
                             width={970}
                             height={441}/>
                    </picture>
                </p>
                <h3>Step 3. Generated Data File Settings</h3>
                <ul>
                    <li>Choose the <b>number of fields</b> you want to generate.</li>
                    <li>Choose how you want to <b>download the generated data file</b> as a Zip archive or a single
                        file.
                    </li>
                    <li><b>Click the ‘Download Data File’</b> button to save your dummy data file.</li>
                </ul>
                <p>
                    {/*<picture>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/3.webp'} type="image/webp"/>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/3.jpg'} type="image/jpeg"/>*/}
                    {/*</picture>*/}
                    <picture>
                        <source srcSet={process.env.PUBLIC_URL + '/img/4_mob.jpg'} media="(max-width: 1023px)"
                                width={510}
                                height={204}/>
                        <source srcSet={process.env.PUBLIC_URL + '/img/4.jpg'} media="(min-width: 1024px)" width={970}
                                height={172}/>
                        <img src={process.env.PUBLIC_URL + '/img/4.jpg'} alt="Generated Data File Settings" width={970}
                             height={172}/>
                    </picture>
                </p>
                <br/>
                <br/>
                <h2>Tips for Generating Random Data with RNDGen</h2>
                <p>
                    <b>Unique field names:</b> When selecting the fields for data generation, make sure to choose unique
                    field
                    names for each column. Avoid using the same field name for multiple columns
                </p>
                <p>
                    <b>Choose the right file format:</b> Depending on what you are using the data for, choose the file
                    format that
                    best suits your needs. For example, if you are working with data analysis, CSV or Excel may be the
                    best
                    option, while JSON or XML may be better for web development.
                </p>
                <p>
                    <b>Dummy Data for Excel:</b> To prevent accidental changes to your excel dummy data, you can protect
                    the cells
                    containing the data by Right-click on the selected cells or menu item “Format”. For dummy numbers,
                    you can use
                    the RANDBETWEEN() function directly in the file.
                </p>
                <p>
                    <b>Incorporate Realistic Field Values:</b> To generate meaningful JSON data, incorporate realistic
                    field
                    values. For example, if generating JSON data for a user profile, include fields such as name, email,
                    age, and
                    address with realistic values. This helps create data that will enhance testing and analysis.
                </p>
                <p>
                    <b>Define mock data types and formats:</b> When generating CSV dummy data, it's essential to define
                    the fake
                    data types and formats for each column. This ensures consistency and compatibility with downstream
                    processes
                    that may rely on specific data types. For example, if a column represents dates, ensure that the
                    generated
                    data adheres to a specific date format.
                </p>
            </Container>
        </Container>
    );
}


export default GeneratorFileContent;