import React from 'react';
import {PasswordContext} from '../context/PasswordContext';
import {usePassword} from '../hooks/password.hook';
import {GenerateContainer} from '../components/GeneratePassword/GenerateContainer';
import Copyright from '../components/Copyright';
import GeneratorFileContent from '../components/GeneratePassword/Content';
import {Helmet} from 'react-helmet';
import content from '../config/content';


export const PasswordPage = () => {
    return (
        <>
            <Helmet>
                <title>{content.password_page.title}</title>
                <link rel="canonical" href={content.password_page.canonical}/>
                <meta name="description" content={content.password_page.description}/>
                <meta name="keywords" content={content.password_page.keywords}/>
                <meta property="og:title" content={content.password_page.title}/>
                <meta property="og:description" content={content.password_page.description}/>
                <meta property="og:url" content={content.password_page.ogUrl}/>
                <meta property="og:image" content={content.password_page.ogImage}/>
                <meta name="designer" content={content.password_page.designer}/>
            </Helmet>
            <PasswordContext.Provider value={usePassword()}>
                <GenerateContainer/>
                <GeneratorFileContent/>
                <Copyright/>
            </PasswordContext.Provider>
        </>
    );
};