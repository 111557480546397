import React, {useContext, useEffect, useState} from 'react';
import {GenerateContext} from '../../context/GenerateContext';
import {PreloaderContext} from '../../context/PreloaderContext';
import {Container, Form, InputGroup, Button, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {useHttp} from '../../hooks/http.hook';
import {useMessage} from '../../hooks/message.hook';
import {CookieContext} from '../../context/CookieContext';
import moment from 'moment';

export const GenerateAI = () => {
    const symbolLimit = 150;
    const cookiename = moment().format('L');
    const generate = useContext(GenerateContext);
    const preloader = useContext(PreloaderContext);
    const cookie = useContext(CookieContext);
    const {request} = useHttp();
    const message = useMessage();
    const [text, setText] = useState();
    const [error, setError] = useState('');
    const [userLimit, setUserLimit] = useState(0);
    const [usersLimit, setUsersLimit] = useState(0);
    const [currentSymbols, setCurrentSymbols] = useState(0);


    useEffect(() => {
        limitHandler().then();
    }, []);
    const changeHandler = event => {
        setText(event.target.value);
        setCurrentSymbols(event.target.value.length);
    };

    const limitHandler = async () => {
        try {
            const {limitAll, limitByUser} = await request('/api/generate/limit/ai', 'POST');
            setUsersLimit(limitAll);

            limitCookieHandler({use: false, limit: limitByUser});
        } catch (e) {
            message(e.message, 'error');
            setError(e.message);
        }
    };

    const limitCookieHandler = data => {
        const use = data.use ?? false;
        const uLimit = data.limit ?? 0;

        if (cookie.global.has(cookiename)) {
            let cookieLimit = cookie.global.get(cookiename).limit;
            if (use) {
                cookieLimit--;
            }

            if (cookieLimit <= 0) {
                setUserLimit(0);
                cookie.global.set(cookiename, {limit: 0});
                return;
            }

            if (cookieLimit < uLimit) {
                setUserLimit(cookieLimit);
                cookie.global.set(cookiename, {limit: cookieLimit});
            }
            if (cookieLimit > uLimit) {
                setUserLimit(uLimit);
                cookie.global.set(cookiename, {limit: uLimit});
            }
            if (cookieLimit === uLimit) {
                setUserLimit(uLimit);
            }
        } else {
            setUserLimit(uLimit);
            cookie.global.set(cookiename, {limit: uLimit});
        }

    };

    const fetchHandler = async () => {
        preloader.showPreloader();

        if (usersLimit === 0 || userLimit === 0) {
            setError('Message limit reached');
            message('Message limit reached', 'error');
            return;
        }

        try {
            setError(null);
            generate.ai.setRequest(text);
            const response = await request('/api/generate/run/template', 'POST', {
                text: text
            });

            generate.ai.setResponse(response.fields);
            generate.ai.setRowsAi(response.fields);
        } catch (e) {
            message(e.message, 'error');
            setError(e.message);
        }

        await limitHandler().then();
        preloader.hidePreloader();

    };


    return (
        <Container fluid className=" container-ai">
            <Container>
                <Form.Group controlId="formBasicEmail" className="container-color-2">
                    <Form.Label>
                        Create fields automatically
                        <OverlayTrigger
                            placement={`top`}
                            overlay={
                                <Tooltip id={`ai-tooltip-top`}>
                                    This is our test AI-powered functionality, designed to generate sets or systems of fields tailored to your specific needs. For example, you can request 'create fields for a company', and our AI will promptly generate a relevant set of fields. It generates data based on our fake data generator, and it may not look exactly as you want, but the structure will be close to what you wish. We will add synthetic data generation soon. No chat history is supported for now.
                                </Tooltip>
                            }
                        >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1096_1678)">
                                    <path
                                        d="M8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346629 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87104 0 6.41775 0 8C0.00229405 10.121 0.845886 12.1545 2.34568 13.6543C3.84547 15.1541 5.87897 15.9977 8 16ZM8 3.33334C8.19778 3.33334 8.39112 3.39199 8.55557 3.50187C8.72002 3.61175 8.84819 3.76793 8.92388 3.95065C8.99957 4.13338 9.01937 4.33445 8.98079 4.52843C8.9422 4.72241 8.84696 4.90059 8.70711 5.04044C8.56726 5.1803 8.38907 5.27554 8.19509 5.31412C8.00111 5.35271 7.80004 5.3329 7.61732 5.25722C7.43459 5.18153 7.27841 5.05336 7.16853 4.88891C7.05865 4.72446 7 4.53112 7 4.33334C7 4.06812 7.10536 3.81377 7.29289 3.62623C7.48043 3.43869 7.73478 3.33334 8 3.33334ZM7.33333 6.66667H8C8.35362 6.66667 8.69276 6.80715 8.94281 7.0572C9.19286 7.30724 9.33333 7.64638 9.33333 8V12C9.33333 12.1768 9.2631 12.3464 9.13807 12.4714C9.01305 12.5964 8.84348 12.6667 8.66667 12.6667C8.48986 12.6667 8.32029 12.5964 8.19526 12.4714C8.07024 12.3464 8 12.1768 8 12V8H7.33333C7.15652 8 6.98695 7.92977 6.86193 7.80474C6.73691 7.67972 6.66667 7.51015 6.66667 7.33334C6.66667 7.15653 6.73691 6.98696 6.86193 6.86193C6.98695 6.73691 7.15652 6.66667 7.33333 6.66667Z"
                                        fill="#4D5560"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1096_1678">
                                        <rect width="16" height="16" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </OverlayTrigger>
                        <span className={`ai-info-top`}>Algorithm updated 14 March 2024 | Synthetic Data coming soon</span>
                    </Form.Label>
                    <InputGroup className="mb-2 input-group-ai">
                        <div className="ai-input-container">
                            <span className="ai-limit-symbols">{currentSymbols}/{symbolLimit}</span>
                            <Form.Control type="text" value={text} onChange={changeHandler}
                                          placeholder="Example: Logistic company..."
                                          maxLength={symbolLimit}
                                          onKeyPress={event => {
                                              if (event.key === "Enter") {
                                                  fetchHandler().then();
                                              }
                                          }}
                                          disabled={usersLimit === 0 || userLimit === 0}/>
                        </div>
                        <Button variant="outline-secondary" id="button-addon2" className="btn btn-primary"
                                onClick={fetchHandler}
                                disabled={usersLimit === 0 || userLimit === 0}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.2174 11.2083H13.7826C14.4549 11.2083 15 11.7307 15 12.375V12.8333C15 13.4777 14.4549 14 13.7826 14H2.2174C1.54506 14 1 13.4777 1 12.8333V12.375C1 11.7307 1.54506 11.2083 2.2174 11.2083Z"
                                    fill="white"/>
                                <path
                                    d="M2.2174 6.1042H13.7826C14.4549 6.1042 15 6.62655 15 7.27088V7.72921C15 8.37353 14.4549 8.89588 13.7826 8.89588H2.2174C1.54506 8.89586 1 8.37353 1 7.72921V7.27088C1 6.62652 1.54506 6.1042 2.2174 6.1042Z"
                                    fill="white"/>
                                <path
                                    d="M2.2174 1.00002H13.7826C14.4549 1.00002 15 1.52236 15 2.16669V2.62502C15 3.26934 14.4549 3.79169 13.7826 3.79169H2.2174C1.54506 3.79169 1 3.26934 1 2.62502V2.16669C1 1.52236 1.54506 1.00002 2.2174 1.00002Z"
                                    fill="white"/>
                            </svg>
                            Generate
                        </Button>
                    </InputGroup>
                    <div className="d-flex justify-content-between alerts-ai">
                        <Form.Text className={'text-muted ' + (userLimit === 0 ? 'text-danger' : '')}>
                            You have {userLimit} free generations today
                        </Form.Text>
                        <Form.Text className="text-danger">
                            {error}
                        </Form.Text>
                    </div>
                </Form.Group>
            </Container>
        </Container>
    );
};