import React, {useCallback, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {Container, Stack, Table, Pagination} from 'react-bootstrap';
import {useHttp} from '../../hooks/http.hook';
import moment from 'moment';

export const AdminAIPage = () => {
    const title = `Admin AI Logs`;
    const {request} = useHttp();
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState([]);
    const [paginateTotal, setPaginateTotal] = useState(0);
    const [paginatePerPage, setPaginatePerPage] = useState(0);

    const header = {
        'id': 'ID',
        'user_agent': 'User Agent',
        'ip': 'IP',
        'country': 'Country',
        'ai_request': 'AI Request',
        'ai_result': 'AI Result',
        'createdAt': 'Created At'
    };

    const fetchHandler = useCallback(async body => {
        const response = await request('/api/log/ai', 'POST', body);
        setRows(response.rows);
        setPaginateTotal(response.total);
        setPaginatePerPage(response.perPage);
        setPage(body.page);
    }, []);

    useEffect(() => {
        fetchHandler({page: page}).then();
    }, []);

    const HeaderData = () => {
        return (
            <thead>
            <tr>
                <th width={`30px`} className={`text-nowrap text-center`}>ID</th>
                <th width={`150px`} className={`text-nowrap text-center`}>User Agent</th>
                <th className={`text-nowrap text-center`}>IP</th>
                <th className={`text-nowrap text-center`}>Country</th>
                <th className={`text-nowrap text-center`}>AI Request</th>
                <th className={`text-nowrap text-center`}>AI Result</th>
                <th width={`160px`} className={`text-nowrap text-center`}>Created At</th>
            </tr>
            </thead>
        );
    };

    const BodyData = () => {

        if (!rows.length) {
            return (
                <tbody>
                <tr>
                    <td colSpan={++Object.values(header).length}>
                        <center>Not Data!!</center>
                    </td>
                </tr>
                </tbody>
            );
        }
        return (
            <tbody>
            {rows.map((item, index) => (
                    <tr key={index} className={`align-middle`}>
                        <td className={`text-center`}>{item.id}</td>
                        <td>{item.user_agent}</td>
                        <td className={`text-center`}>{item.ip}</td>
                        <td className={`text-center`}>{item.country ?? '-'}</td>
                        <td className={`text-center`}>{item.ai_request ?? '-'}</td>
                        <td>
                            <pre>{JSON.stringify(item.ai_result, null, 2)}</pre>
                        </td>
                        <td className={`text-center`}>
                            <p>{moment(item.createdAt).format('MMMM Do YYYY')}</p>
                            <p>{moment(item.createdAt).format('h:mm:ss a')}</p>
                        </td>
                    </tr>
                )
            )}
            </tbody>
        )
            ;

    };

    const PG = (total, page, perPage) => {
        const firstPage = 1;
        const lastPage = Math.ceil(total / perPage);
        if (lastPage === 1) {
            return (<></>);
        }
        const prevPage = (page - 1) ? page - 1 : 1;
        const nextPage = (page + 1 <= lastPage) ? page + 1 : lastPage;

        const isDisableFirst = firstPage === page;
        const isDisableLast = firstPage === lastPage;
        const isDisablePrev = firstPage === page;
        const isDisableNext = lastPage === page;
        return (
            <Pagination>
                <Pagination.First onClick={() => fetchHandler({page: firstPage})} disabled={isDisableFirst}/>
                <Pagination.Prev onClick={() => fetchHandler({page: prevPage})} disabled={isDisablePrev}/>
                <Pagination.Next onClick={() => fetchHandler({page: nextPage})} disabled={isDisableNext}/>
                <Pagination.Last onClick={() => fetchHandler({page: lastPage})} disabled={isDisableLast}/>
            </Pagination>
        );
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title}/>
            </Helmet>

            <Container fluid className={`container-color-1`}>
                <Container>
                    <Stack direction="horizontal" gap={3} className="w-100 generator-stack-top">
                        <div className="generator-stack-top-title">
                            <h1>{title}</h1>
                        </div>
                    </Stack>
                </Container>
                <Container fluid={`md`}>
                    <Table responsive striped bordered hover size="sm">
                        <HeaderData/>
                        <BodyData/>
                    </Table>
                </Container>
                <Container fluid={`md`} className={`d-flex justify-content-center`}>
                    {PG(paginateTotal, page, paginatePerPage)}
                </Container>
            </Container>
        </>
    );
};