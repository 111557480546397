import {useCallback, useContext, useEffect, useState} from 'react';
import {CookieContext} from '../context/CookieContext';
import {useHttp} from './http.hook';

const RANDOM_BATCH_SIZE = 256;

export const usePassword = () => {
    const {request} = useHttp();
    const cookie = useContext(CookieContext);
    const randomBytesHandler = require('randombytes');
    const defaultSizePassword = 16;
    let randomIndex,
        randomBytes = {},
        lowercase = 'abcdefghijklmnopqrstuvwxyz',
        uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        numbers = '0123456789',
        symbols = '@#$%!?&*^=_',
        symbols_additional = '()+-{}[]|:;"/.><,`~',
        similarCharacters = /[ilLI|`oO0]/g,
        strictRules = [
            {name: 'lowercase', rule: /[a-z]/},
            {name: 'uppercase', rule: /[A-Z]/},
            {name: 'numbers', rule: /[0-9]/},
            {name: 'symbols', rule: /[@#$%!?&*^=_]/},
            {name: 'symbols_additional', rule: /[()+-{}[\]|:;"\/.><,`~]/}
        ];

    const [sizePassword, setSizePassword] = useState(cookie.global.getByKeyDef(`passwordGen`, defaultSizePassword, `length`));
    const [minSizeMarker, setMinSizeMarker] = useState(4);
    const [maxSizeMarker, setMaxSizeMarker] = useState(4);

    const sizeMarks = {
        4: '4',
        8: '8',
        16: '16',
        24: '24',
        32: '32',
        40: '40',
        48: '48',
        56: '56',
        64: '64',
        72: '72',
        80: '80',
        88: '88',
        96: '96',
        104: '104',
        112: '112',
        120: '120',
        128: '128',
    };

    useEffect(() => {
        let max = parseInt(Object.keys(sizeMarks).pop());
        setMaxSizeMarker(max);
    }, []);

    const getNextRandomValue = useCallback(() => {
        if (randomIndex === undefined || randomIndex >= randomBytes.length) {
            randomIndex = 0;
            randomBytes = randomBytesHandler(RANDOM_BATCH_SIZE);
        }

        let result = randomBytes[randomIndex];
        randomIndex++;

        return result;
    }, []);

    const randomNumber = useCallback(max => {
        let rand = getNextRandomValue();
        while (rand >= 256 - (256 % max)) {
            rand = getNextRandomValue();
        }
        return rand % max;
    }, []);

    const generator = useCallback((options, pool) => {
        let password = '',
            optionsLength = options.length,
            poolLength = pool.length;

        if (options.duplicate) {
            optionsLength = optionsLength >= pool.length ? pool.length : optionsLength;
            setSizePassword(optionsLength);
            let tempPool = pool.split('').sort(() => Math.random() - 0.5);
            password = tempPool.join('').slice(0, optionsLength);
        } else {
            for (let i = 0; i < optionsLength; i++) {
                password += pool[randomNumber(poolLength)];
            }
        }

        if (options.strict) {
            let fitsRules = strictRules.every(function (rule) {
                if (options[rule.name] == false) return true;

                if (rule.name === 'symbols' && typeof options[rule.name] === 'string') {
                    // Create a regular expression from the provided symbols
                    let re = new RegExp('[' + options[rule.name] + ']');
                    return re.test(password);
                }
                return rule.rule.test(password);
            });

            if (!fitsRules) return generator(options, pool);
        }

        return password;
    }, []);

    const generate = useCallback(options => {
        options = options || {};
        if (!Object.prototype.hasOwnProperty.call(options, 'length')) options.length = 10;
        if (!Object.prototype.hasOwnProperty.call(options, 'numbers')) options.numbers = false;
        if (!Object.prototype.hasOwnProperty.call(options, 'symbols')) options.symbols = false;
        if (!Object.prototype.hasOwnProperty.call(options, 'symbols_additional')) options.symbols_additional = false;
        if (!Object.prototype.hasOwnProperty.call(options, 'exclude')) options.exclude = '';
        if (!Object.prototype.hasOwnProperty.call(options, 'uppercase')) options.uppercase = true;
        if (!Object.prototype.hasOwnProperty.call(options, 'lowercase')) options.lowercase = true;
        if (!Object.prototype.hasOwnProperty.call(options, 'excludeSimilarCharacters')) options.excludeSimilarCharacters = false;
        if (!Object.prototype.hasOwnProperty.call(options, 'strict')) options.strict = false;
        if (!Object.prototype.hasOwnProperty.call(options, 'duplicate')) options.duplicate = false;

        if (options.strict) {
            let minStrictLength = 1 + (options.numbers ? 1 : 0) + (options.symbols ? 1 : 0) + (options.uppercase ? 1 : 0);
            if (minStrictLength > options.length) {
                throw new TypeError('Length must correlate with strict guidelines');
            }
        }

        let pool = '';

        if (options.lowercase) {
            pool += lowercase;
        }

        if (options.uppercase) {
            pool += uppercase;
        }
        if (options.numbers) {
            pool += numbers;
        }

        if (options.symbols) {
            if (typeof options.symbols === 'string') {
                pool += options.symbols;
            } else {
                pool += symbols;
            }
        }
        if (options.symbols_additional) {
            if (typeof options.symbols_additional === 'string') {
                pool += options.symbols_additional;
            } else {
                pool += symbols_additional;
            }
        }

        if (!pool) {
            throw new TypeError('At least one rule for pools must be true');
        }

        if (options.excludeSimilarCharacters) {
            pool = pool.replace(similarCharacters, '');
        }

        let i = options.exclude.length;
        while (i--) {
            pool = pool.replace(options.exclude[i], '');
        }

        return generator(options, pool);

    }, []);

    const generateMultiple = useCallback((amount, options) => {
        let passwords = [];

        for (let i = 0; i < amount; i++) {
            passwords[i] = generate(options);
        }

        return passwords;
    }, []);

    const buttonHandler = useCallback((event, size) => {
        const keys = Object.keys(sizeMarks);
        if (event === 'prev') {
            let filter = keys.filter(item => parseInt(item) < parseInt(size)),
                last = filter.pop();
            if (last) {
                setSizePassword(last);
            }
        }
        if (event === 'next') {
            let filter = keys.filter(item => parseInt(item) > parseInt(size)),
                first = filter.shift();
            if (first) {
                setSizePassword(first);
            }
        }
    }, []);


    const fetchLogHandler = useCallback(async body => {
        return await request('/api/log/pass_gen/create', 'POST', body);
    }, []);

    return {
        generate,
        generateMultiple,
        setting: {
            defaultSizePassword,
            sizePassword,
            setSizePassword,
            minSizeMarker,
            setMinSizeMarker,
            maxSizeMarker,
            setMaxSizeMarker,
            sizeMarks,
        },
        buttonHandler,
        fetchLogHandler
    };
};