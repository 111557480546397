import React, {useContext} from 'react';
import {ExportContext} from '../../context/ExportContext';
import {Form} from 'react-bootstrap';
import {PreloaderContext} from '../../context/PreloaderContext';

export const ExportFieldSetting = (prop) => {
    const {field, index, handler} = prop;
    const exp = useContext(ExportContext);
    const preloader = useContext(PreloaderContext);

    const onChange = event => {
        const index = event.target.dataset.index;
        const value = event.target.value;

        let updated = exp.setting.getExportSetting;
        const copyUpdated = JSON.parse(JSON.stringify(updated.fields[index]));
        copyUpdated.value = value;
        updated.fields[index] = copyUpdated;
        exp.setting.updateExportSetting(updated);

        exp.counter.set(exp.counter.get + 1);

        handler();
    };

    switch (field.type) {
        case 'text':
            return (
                <Form.Group className="action-field">
                    <Form.Label>{field.label}</Form.Label>
                    <Form.Control
                        type={field.type}
                        value={field.value}
                        data-index={index}
                        onChange={onChange}
                        placeholder={field.label}
                        disabled={preloader.preloader}
                        aria-label={field.label}
                    />
                </Form.Group>
            );
        case 'select':
            return (
                <Form.Group className="action-field">
                    <Form.Label>{field.label}</Form.Label>
                    <Form.Select
                        onChange={onChange}
                        data-index={index}
                        value={field.value}
                        disabled={preloader.preloader}
                        aria-label={field.label}
                    >
                        {field.values.map((option, key) => {
                            return (
                                <option value={option.value}
                                        key={key}>{option.label}</option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
            );
        default:
            return null;
    }
};