import '../../assets/security.scss';
import {Col, Row} from 'react-bootstrap';

export const ComponentSecuriry = (prop) => {
    const {options} = prop;

    const steps = [
        {
            size: `15.78947%`,
            color: `#F93232`,
            text: `😖 No secure`,
        },
        {
            size: `29.64912%`,
            color: `#F9A932`,
            text: `😕 Weak`,
        },
        {
            size: `42.98245%`,
            color: `#8AC2E1`,
            text: `😐 Normal`,
        },
        {
            size: `54.73684%`,
            color: `#93DCCA`,
            text: `🙂 Good`,
        },
        {
            size: `68.07017%`,
            color: `#50BA81`,
            text: `😎 Strong`,
        },
        {
            size: `83.68421%`,
            color: `#0168E3`,
            text: `🤩 Very Strong`,
        },
        {
            size: `100%`,
            color: `#9526BC`,
            text: `😈 Unreal`
        },
    ];


    let charLength = 0;
    if (options.numbers) {
        charLength++;
    }
    if (options.symbols) {
        charLength++;
    }
    if (options.symbols_additional) {
        charLength++;
    }
    if (options.uppercase) {
        charLength++;
    }
    if (options.lowercase) {
        charLength++;
    }


    let countLength = -2;
    if (options.length >= 8 && options.length <= 15) {
        countLength = 0;
    }
    if (options.length >= 16 && options.length <= 31) {
        countLength = 1;
    }
    if (options.length >= 32 && options.length <= 127) {
        countLength = 2;
    }
    if (options.length >= 128) {
        countLength = 3;
    }

    let math = charLength + countLength - 1;
    if (math <= 0) {
        math = 0;
    }
    if (math >= 6) {
        math = 6;
    }

    const current = steps[math];

    const status = options.numbers
        || options.lowercase
        || options.uppercase
        || options.symbols_additional
        || options.symbols;


    return (
        <Row>
            <Col md={{offset: 3, span: 6}}>
                <div className={`security-container`}>
                    <div className={`security-line-container`}>
                        <div
                            className={`security-line`}
                            style={{
                                width: status ? current.size : 0,
                                backgroundColor: current.color
                            }}
                        />
                    </div>
                    {status ? current.text : ``}
                </div>
            </Col>
        </Row>
    );
};