import Toast from 'react-bootstrap/Toast';
import {Button} from 'react-bootstrap';
import {CookieIcon} from './Icons';
import {useContext} from 'react';
import {CookieContext} from '../context/CookieContext';
import '../assets/cookie.scss';

function Cookie() {
    const cookie = useContext(CookieContext);

    return (
        <Toast
            show={cookie.popupStatus}
            className={`toast-cookie-container`}
            onClose={cookie.closeCookieHandler}
        >
            <Toast.Header/>
            <div className={`cookie-row`}>
                <div className={`toast-cookie-icon`}>
                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="32" cy="32" r="32" fill="#FFEBD5"/>
                        <path
                            d="M37.0638 32.5017C34.9987 30.2837 33.0357 27.9892 31.3785 25.4397C30.2058 23.6041 29.1605 21.5391 27.9113 20.2644C26.7896 19.0916 26.0757 17.8424 25.7188 16.5677C23.2203 17.2815 21.4867 17.8934 19.2432 20.2389C3.61515 36.3513 26.2542 61.2339 43.6413 44.8665C44.9161 43.6682 44.4572 42.2151 45.222 40.9148C42.8 38.1105 39.6132 35.2296 37.0638 32.5017Z"
                            fill="#DE814B"/>
                        <path
                            d="M43.642 44.8665C27.5296 57.9451 5.09452 37.1671 19.2439 20.2134C19.4734 19.9584 19.7283 19.678 19.9832 19.423C23.221 16.2108 26.8157 14.7066 30.334 14.4771C30.6909 14.4516 30.9713 14.7321 30.9968 15.0635C31.0478 17.154 32.399 18.5562 34.3876 18.8367C34.6935 18.8877 34.872 19.1681 34.7955 19.474C34.5915 20.1369 34.515 20.8507 34.515 21.5901C34.5915 25.3377 37.7018 28.4226 41.4495 28.4481C42.2144 28.4481 42.9537 28.3461 43.6675 28.1166C43.948 28.0147 44.2539 28.1931 44.3049 28.4991C44.7638 30.9975 47.0328 30.5386 48.1036 30.1817C48.4095 30.0797 48.7409 30.2582 48.8174 30.5641C50.9079 38.1869 43.642 44.8665 43.642 44.8665Z"
                            fill="#F6B059"/>
                        <path
                            d="M32.7039 28.7541C32.7039 30.6407 31.1742 31.9154 29.2876 31.9154C27.4011 31.9154 25.8714 30.6407 25.8714 28.7541C25.8714 26.8675 27.4011 25.3378 29.2876 25.3378C31.1742 25.3378 32.7039 26.842 32.7039 28.7541Z"
                            fill="#6D420D"/>
                        <path
                            d="M43.4371 36.9886C43.4371 38.4418 42.2644 39.6146 40.8112 39.6146C39.358 39.6146 38.3637 38.7478 38.3637 37.2946C38.3637 35.8414 39.358 34.3372 40.8112 34.3372C42.2644 34.3372 43.4371 35.5355 43.4371 36.9886Z"
                            fill="#6D420D"/>
                        <path
                            d="M23.8073 33.7764C23.8073 34.8981 22.9914 35.5355 21.8697 35.5355C20.7479 35.5355 19.7791 34.8981 19.7791 33.7764C19.7791 32.6546 20.6714 31.7623 21.7932 31.7623C22.915 31.7623 23.8073 32.6546 23.8073 33.7764Z"
                            fill="#6D420D"/>
                        <path
                            d="M29.9756 40.6088C29.9756 41.1187 29.5677 41.5266 29.0578 41.5266C28.5479 41.5266 28.14 41.1187 28.14 40.6088C28.14 40.099 28.5479 39.691 29.0578 39.691C29.5677 39.691 29.9756 40.099 29.9756 40.6088Z"
                            fill="#6D420D"/>
                        <path
                            d="M23.7551 22.9923C24.3183 22.9923 24.7749 22.5357 24.7749 21.9725C24.7749 21.4093 24.3183 20.9528 23.7551 20.9528C23.1919 20.9528 22.7354 21.4093 22.7354 21.9725C22.7354 22.5357 23.1919 22.9923 23.7551 22.9923Z"
                            fill="#6D420D"/>
                        <path
                            d="M42.1875 15.8541C42.2894 17.0778 41.2187 18.0721 39.791 18.0721C38.3633 18.0721 37.267 16.9759 37.3435 15.6247C37.42 14.2734 38.4908 13.2792 39.74 13.4066C40.9892 13.5341 42.0855 14.6049 42.1875 15.8541Z"
                            fill="#F6B059"/>
                        <path
                            d="M40.3525 17.5875C40.9134 17.5875 41.4233 17.4345 41.8312 17.1541C41.3978 17.7149 40.6839 18.0719 39.8171 18.0719C38.3894 18.0719 37.2932 16.9756 37.3696 15.6499C37.4206 14.8341 37.8285 14.1457 38.4149 13.7633C38.1345 14.1457 37.9305 14.6301 37.905 15.1655C37.854 16.4657 38.9503 17.562 40.3525 17.5875Z"
                            fill="#DE814B"/>
                        <path
                            d="M42.0601 22.788C42.0091 23.6038 41.3208 24.2922 40.5305 24.3687C39.7401 24.4196 39.1028 23.7568 39.1283 22.89C39.1283 22.0232 39.8166 21.3093 40.6579 21.3093C41.4993 21.3093 42.1111 21.9722 42.0601 22.788Z"
                            fill="#F6B059"/>
                        <path
                            d="M40.8353 24.0118C41.1412 23.9863 41.4471 23.8589 41.7021 23.6804C41.4216 24.0628 40.9882 24.3178 40.5038 24.3688C39.7135 24.4197 39.1017 23.7569 39.1017 22.8901C39.1017 22.3802 39.3566 21.8958 39.739 21.6154C39.5606 21.8703 39.4331 22.2017 39.4331 22.5332C39.4076 23.4 40.0449 24.0628 40.8353 24.0118Z"
                            fill="#DE814B"/>
                        <path
                            d="M52.4873 23.4256C52.4109 24.3944 51.6205 25.2102 50.6772 25.2612C49.7594 25.3122 49.0201 24.5474 49.0201 23.5276C49.0456 22.5078 49.8359 21.692 50.8302 21.692C51.8245 21.692 52.5638 22.4823 52.4873 23.4256Z"
                            fill="#F6B059"/>
                        <path
                            d="M51.0592 24.8788C51.4417 24.8533 51.7986 24.7003 52.079 24.4708C51.7476 24.9297 51.2377 25.2357 50.6768 25.2612C49.759 25.3122 49.0197 24.5473 49.0197 23.5275C49.0197 22.9157 49.3256 22.3803 49.759 22.0234C49.5296 22.3293 49.4021 22.7117 49.4021 23.1196C49.4021 24.1649 50.1414 24.9297 51.0592 24.8788Z"
                            fill="#DE814B"/>
                    </svg>
                </div>
                <Toast.Body>
                    RNDGen uses <b>cookies</b> to enhance your experience and remember your settings. By clicking <b>“Accept
                    Cookies”</b>, you agree to store <b>small data</b> files on your device to save your preferences and
                    improve
                    your overall experience. <b>Some features</b> such as 'Generate at Start' and 'Save in Browser' will
                    not work
                    without <b>cookies enabled</b>. We take your privacy and security seriously and use SSL encryption
                    to protect
                    your data. Please allow cookies to enjoy a more personalized and secure password generation
                    experience with
                    RNDGen.
                </Toast.Body>
            </div>
            <div className={`cookie-row-btn`}>
                <div className={`toast-cookie-btn-group`}>
                    <Button onClick={cookie.acceptCookieHandler}>
                        <CookieIcon/>
                        Accept cookies
                    </Button>
                    <Button
                        variant="link"
                        onClick={cookie.declineCookieHandler}
                    >Decline</Button>
                </div>
            </div>
        </Toast>
    );
}

export default Cookie;