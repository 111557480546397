import React, {useContext, useEffect, useState} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {GenerateContext} from '../../context/GenerateContext';
import {FieldTitle} from '../_fields/field.title.component';
import {FieldSetting} from '../_fields/field.setting.component';

import {EnableIcon, DisableIcon, DeleteIcon, MenuSmallIcon} from '../Icons';
import {Tooltip, OverlayTrigger, Button} from 'react-bootstrap';
import {ChangeTypePopup} from '../PopupChangeType';
import {PreloaderContext} from '../../context/PreloaderContext';


export const GenerateRow = ({row, indexRow}) => {
    const generate = useContext(GenerateContext);
    const preloader = useContext(PreloaderContext);
    const [statusTypePopup, setStatusTypePopup] = useState(false);


    useEffect(() => {
        document.activeElement.blur();
    }, [statusTypePopup]);

    const changeTypeHandler = (value, index) => {
        const currentOption = JSON.parse(JSON.stringify(generate.getTemplate.find(item => item.key === value)));
        let updatedRows = generate.rows.getRows.slice();
        updatedRows[index].field = currentOption.field;
        updatedRows[index].fields = currentOption.fields;
        updatedRows[index].setting = currentOption.setting;
        updatedRows[index].label = currentOption.label;
        updatedRows[index].key = value;
        generate.rows.update(updatedRows);
    };

    const changeNameHandler = event => {
        const index = event.target.dataset.index;
        let updatedRows = generate.rows.getRows.slice();
        updatedRows[index].name = event.target.value;
        generate.rows.update(updatedRows);
    };

    const changeStatusHandler = index => {
        let updatedRows = generate.rows.getRows.slice();
        updatedRows[index].active = !updatedRows[index].active;
        generate.rows.update(updatedRows);
    };

    const isValidName = name => {
        return generate.rows.getRows.filter(row => row.name === name).length === 1;
    };


    return (
        <Draggable
            key={row.id}
            draggableId={row.id}
            index={indexRow}
        >
            {(provider, snapshot) => {

                const ButtonActive = () => {
                    return (
                        <OverlayTrigger
                            key={`active`}
                            placement={`top`}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    {row.active ? `Disable` : `Enable`}
                                </Tooltip>
                            }
                        >
                            <Button variant="icon"
                                    aria-label={`Switcher status`}
                                    title={`Switcher status`}
                                    onClick={() => changeStatusHandler(indexRow)}
                                    disabled={preloader.preloader}
                            >
                                {row.active ?
                                    <EnableIcon className="icon-gray icon-hover-blue"/> :
                                    <DisableIcon className="icon-gray icon-hover-blue"/>
                                }
                            </Button>
                        </OverlayTrigger>
                    );
                };
                const ButtonRemove = () => {
                    return (
                        <OverlayTrigger
                            key={`remove`}
                            placement={`top`}
                            overlay={<Tooltip id={`tooltip-top`}>Remove</Tooltip>}
                        >
                            <Button variant="icon"
                                    aria-label={`Button remove`}
                                    title={`Button remove`}
                                    onClick={() => generate.rows.remove(generate.rows.getRows, indexRow)}
                                    disabled={preloader.preloader}
                            >
                                <DeleteIcon className="icon-gray icon-hover-red"/>
                            </Button>
                        </OverlayTrigger>
                    );
                };

                return (
                    <div className="table-flex-row"
                         ref={provider.innerRef}
                         {...provider.draggableProps}
                         {...provider.dragHandleProps}
                         style={{
                             userSelect: 'none',
                             border: snapshot.isDragging ? '1px solid #ddd' : '',
                             ...provider.draggableProps.style
                         }}
                         key={indexRow}
                         role="group"
                    >

                        <div className="table-flex-col">
                            <MenuSmallIcon/>
                        </div>
                        <div className={`table-flex-col`} data-title={`FIELD NAME:`}>
                            <FieldTitle
                                value={row.name}
                                onChange={changeNameHandler}
                                indexRow={indexRow}
                                invalid={!isValidName(row.name)}
                            />
                        </div>
                        <div className="table-flex-col" data-title={`TYPE:`}>
                            <div
                                className={`selectIsNotReal ${preloader.preloader ? 'input-disabled' : ''}`}
                                onClick={() => setStatusTypePopup(true)}
                            >
                                <span>{row.label}</span>
                                <span>
                                    <svg width="9" height="5" viewBox="0 0 9 5" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.680423 0H8.31958C8.45471 0.000546568 8.58665 0.0397348 8.69869 0.11261C8.81074 0.185485 8.89787 0.288774 8.94907 0.409415C9.00026 0.530057 9.01323 0.662633 8.98631 0.79038C8.9594 0.918128 8.89383 1.03531 8.79788 1.12711L4.98513 4.80503C4.92161 4.86681 4.84604 4.91585 4.76278 4.94931C4.67951 4.98277 4.5902 5 4.5 5C4.4098 5 4.32049 4.98277 4.23722 4.94931C4.15396 4.91585 4.07839 4.86681 4.01487 4.80503L0.202122 1.12711C0.106173 1.03531 0.0405975 0.918128 0.0136861 0.79038C-0.0132253 0.662633 -0.00026383 0.530057 0.0509319 0.409415C0.102128 0.288774 0.189258 0.185485 0.301306 0.11261C0.413354 0.0397348 0.545287 0.000546568 0.680423 0Z"
                                            fill="black"/>
                                    </svg>
                                </span>
                            </div>
                            {statusTypePopup ?
                                <ChangeTypePopup
                                    scrollable={false}
                                    indexRow={indexRow}
                                    closeHandler={setStatusTypePopup}
                                    changeTypeHandler={changeTypeHandler}
                                />
                                : ''}
                        </div>
                        <div className="table-flex-col td-example" data-title={`EXAMPLE:`}>
                            {generate.demo.rowData && generate.demo.rowData[indexRow] ? generate.demo.rowData[indexRow] : ''}
                        </div>
                        <div
                            className={`table-flex-col table-flex-setting ${Object.entries(row.fields).length ? '' : 'table-flex-setting-empty'}`}
                            data-title={Object.entries(row.fields).length ? `SETTINGS:` : ``}>
                            <div className={`td-fields`}>
                                {Object.entries(row.fields).map(([indexItem, field], i) => {
                                    return (
                                        <FieldSetting
                                            field={field}
                                            indexItem={indexItem}
                                            indexRow={indexRow}
                                            key={i}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div className={`table-flex-col table-action`}>
                            <div className={`table-action-container`}>
                                <ButtonActive/>
                                <ButtonRemove/>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Draggable>
    );
};