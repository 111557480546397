import {Modal, Button, Form} from 'react-bootstrap';
import React, {useContext, useState} from 'react';
import {PreloaderContext} from '../context/PreloaderContext';
import * as formik from 'formik';
import * as yup from 'yup';
import {useHttp} from '../hooks/http.hook';
import {useMessage} from '../hooks/message.hook';
import isEmailValidator from 'validator/lib/isEmail';

export const Feedback = () => {
    const {Formik} = formik;
    const {request} = useHttp();
    const message = useMessage();

    const schema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string().required().email().test("is-valid", (message) => `${message.path} is invalid`, (value) => value ? isEmailValidator(value) : new yup.ValidationError("Invalid value")),
        category: yup.string().required(),
        description: yup.string().required(),
    });

    const [show, setShow] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const preloader = useContext(PreloaderContext);
    const handleClose = () => setShow(false);
    const handleCloseSuccess = () => setShowSuccess(false);
    const handleShow = () => setShow(true);
    const handleShowSuccess = () => setShowSuccess(true);

    const submitHandler = async data => {
        preloader.showPreloader();
        try {
            await request('/api/mail/feedback', 'POST', data);
            handleClose();
            handleShowSuccess();

        } catch (e) {
            message(e.message, 'error');
        }
        preloader.hidePreloader();
    };

    return (
        <>
            <div className="feedback">
                <div className="feedback__button" onClick={handleShow}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="feedback 1">
                            <path id="Vector"
                                  d="M28.125 14.375C23.6448 14.375 20 18.3002 20 23.1249C20 27.9498 23.6448 31.875 28.125 31.875C32.6052 31.875 36.25 27.9498 36.25 23.1249C36.25 18.3002 32.6052 14.375 28.125 14.375ZM24.9584 20.5282C24.9584 20.1489 25.2439 19.8414 25.5962 19.8414C25.9484 19.8414 26.2339 20.1489 26.2339 20.5282V21.2685C26.2339 21.6478 25.9484 21.9553 25.5962 21.9553C25.2439 21.9553 24.9584 21.6478 24.9584 21.2685V20.5282ZM31.1048 26.2854C30.2833 27.17 29.2041 27.6124 28.125 27.6124C27.0459 27.6124 25.9667 27.17 25.1452 26.2854C24.8961 26.0171 24.8961 25.5823 25.1452 25.3141C25.3942 25.0458 25.798 25.0459 26.0471 25.314C27.1929 26.5479 29.0571 26.5479 30.2029 25.314C30.452 25.0459 30.8558 25.0458 31.1048 25.3141C31.3539 25.5823 31.3539 26.0171 31.1048 26.2854ZM31.369 21.2685C31.369 21.6478 31.0834 21.9552 30.7312 21.9552C30.379 21.9552 30.0935 21.6478 30.0935 21.2685V20.5282C30.0935 20.1489 30.379 19.8414 30.7312 19.8414C31.0834 19.8414 31.369 20.1489 31.369 20.5282V21.2685Z"
                                  fill="#0168E3"/>
                            <path id="Vector_2"
                                  d="M5.69865 11.875H10.5513C10.9372 11.875 11.25 11.5952 11.25 11.25C11.25 10.9048 10.9372 10.625 10.5513 10.625H5.69865C5.3128 10.625 5 10.9048 5 11.25C5 11.5952 5.3128 11.875 5.69865 11.875Z"
                                  fill="#4D5560"/>
                            <path id="Vector_3"
                                  d="M14.3045 14.375H5.69548C5.31138 14.375 5 14.6548 5 15C5 15.3452 5.31138 15.625 5.69548 15.625H14.3045C14.6886 15.625 15 15.3452 15 15C15 14.6548 14.6886 14.375 14.3045 14.375Z"
                                  fill="#4D5560"/>
                            <path id="Vector_4"
                                  d="M14.3045 18.125H5.69548C5.31138 18.125 5 18.4048 5 18.75C5 19.0952 5.31138 19.375 5.69548 19.375H14.3045C14.6886 19.375 15 19.0952 15 18.75C15 18.4048 14.6886 18.125 14.3045 18.125Z"
                                  fill="#4D5560"/>
                            <path id="Vector_5"
                                  d="M38.4131 29.262C39.4521 27.4212 40 25.3313 40 23.1996C40 17.378 35.9604 12.4921 30.5646 11.2518V9.21274C30.5646 6.54521 28.4166 4.375 25.7763 4.375H4.78833C2.14802 4.375 0 6.54521 0 9.21274V23.6165C0 26.2841 2.14802 28.4543 4.78833 28.4543H6.07299V34.9698C6.07299 35.2348 6.23098 35.4737 6.47335 35.5751C6.55358 35.6087 6.63781 35.625 6.72136 35.625C6.89011 35.625 7.05599 35.5585 7.18008 35.4331L14.0875 28.4543H16.6847C16.7608 28.4543 16.8335 28.4404 16.9013 28.4161C18.8442 32.5705 23.0314 35.4521 27.8728 35.4521C30.483 35.4521 32.9558 34.6329 35.0457 33.0795L38.6794 34.0632C38.903 34.1239 39.1421 34.0591 39.3058 33.8937C39.4696 33.7281 39.5336 33.4868 39.4736 33.2607L38.4131 29.262ZM13.8189 27.1439C13.6469 27.1439 13.482 27.2129 13.3604 27.3358L7.37004 33.3879V27.7991C7.37004 27.4372 7.07968 27.1439 6.72152 27.1439H4.78834C2.86325 27.1439 1.29705 25.5615 1.29705 23.6165V9.21274C1.29705 7.26776 2.86325 5.68543 4.78834 5.68543H25.7763C27.7014 5.68543 29.2676 7.26776 29.2676 9.21274V11.0287C28.8098 10.9755 28.3445 10.9472 27.8728 10.9472C21.1859 10.9472 15.7457 16.4436 15.7457 23.1996C15.7457 24.5789 15.9726 25.9056 16.3905 27.1439L13.8189 27.1439ZM35.0753 31.7308C34.8791 31.6778 34.6698 31.7205 34.5096 31.8462C32.5961 33.3479 30.3011 34.1416 27.8728 34.1416C21.9011 34.1416 17.0427 29.2331 17.0427 23.1996C17.0427 17.1661 21.9011 12.2576 27.8728 12.2576C33.8446 12.2576 38.703 17.1661 38.703 23.1996C38.703 25.1877 38.1697 27.1345 37.1608 28.8296C37.0701 28.982 37.0446 29.1651 37.0902 29.3368L37.9301 32.5037L35.0753 31.7308Z"
                                  fill="#4D5560"/>
                        </g>
                    </svg>
                    <span>Feedback</span>

                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"
                         className="caret">
                        <path d="M12 0L6 8L0 0H12Z" fill="white"/>
                    </svg>
                </div>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>We want to make RNDGen better!</Modal.Title>
                        <div className="modal-description">
                            We value your input! If you encounter any bugs, wish to add new fields or functionalities,
                            or have any issues with the site. Please don’t hesitate to contact us. Your feedback is
                            crucial in helping us enhance our service. Share your thoughts and help us make our service
                            even better!
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            validationSchema={schema}
                            onSubmit={submitHandler}
                            initialValues={{
                                name: '',
                                email: '',
                                category: '',
                                description: ''
                            }}
                        >
                            {({handleSubmit, handleChange, values, touched, errors}) => (
                                <Form noValidate onSubmit={handleSubmit} id="modal-form-ask">
                                    <div className="form-g-gap">
                                        <div className="form-c-gap">
                                            <Form.Group className="action-field">
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    disabled={preloader.preloader}
                                                    aria-label={`Name`}
                                                    isInvalid={!!errors.name}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="form-c-gap">
                                            <Form.Group className="action-field">
                                                <Form.Control
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    disabled={preloader.preloader}
                                                    aria-label={`Email`}
                                                    isInvalid={!!errors.email}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <Form.Group className="action-field">
                                        <Form.Select
                                            name="category"
                                            onChange={handleChange}
                                            value={values.category}
                                            data-s={values.category}
                                            disabled={preloader.preloader}
                                            aria-label={`Category`}
                                            isInvalid={!!errors.category}
                                            className={`${values.category === `Choose category` || !values.category ? '' : `ph-selected`}`}
                                        >
                                            <option value="">Choose category</option>
                                            <option value="More fields for DataGen">More fields for DataGen</option>
                                            <option value="Bug">Bug</option>
                                            <option value="New Feature">New Feature</option>
                                            <option value="General Inquiry">General Inquiry</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="action-field">
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            placeholder="I need more data fields, such as..."
                                            value={values.description}
                                            onChange={handleChange}
                                            disabled={preloader.preloader}
                                            aria-label={`I need more data fields, such as...`}
                                            style={{height: '179px'}}
                                            isInvalid={!!errors.description}
                                        />
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" form="modal-form-ask">
                            Send Feedback
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showSuccess} onHide={handleCloseSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title>Thank You!</Modal.Title>
                        <div className="modal-description">
                            Your message has been sent.
                        </div>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseSuccess}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};