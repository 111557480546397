import {createContext} from 'react';

function noop() {
}

export const CookieContext = createContext({
    popupStatus: false,
    cookieStatus: false,
    acceptCookieHandler: noop,
    declineCookieHandler: noop,
    closeCookieHandler: noop,
    global: {
        has: noop,
        get: noop,
        set: noop,
        remove: noop,
        getByKeyDef: noop
    }
});