import {Container} from 'react-bootstrap';
import '../../assets/content.scss';
import Widget1 from '../Widgets/Widget_1';

function GeneratorFileContent() {
    return (
        <Container fluid className={`container-generator-file`}>
            <Container className={`container-generator-file-item`}>
                <Widget1/>
                <h2>Random Password Generator</h2>
                <p>
                    <b>Random Password Generator</b> is an online password generator service that helps you easily
                    create strong
                    and secure passwords. RNDGen complex password generator will assist you to protect your data. We
                    use <b>SSL
                    encryption</b> to keep your data safe and create <b>strong passwords on the client side</b>,
                    ensuring complete
                    confidentiality.
                </p>
                <p>
                    Don't settle for a weak password - create a password online for free. <b>Trust RNDGen</b> for all
                    your
                    password-generating needs and enjoy peace of mind with every use. Our advanced password randomizer
                    algorithms
                    generate unique passwords to ensure maximum security for your online accounts.
                </p>
                <p>
                    Try <a href="https://www.rndgen.com/data-generator">Random Data Generator</a> for tests.
                </p>
                <br/>
                <br/>
                <h2>How to Generate Random Password</h2>
                <h3>Step 1: Password Generation Settings</h3>
                <ul>
                    <li><b>Length:</b> Choose the password length using arrows by the slider bar, or type a number
                        manually. There
                        is no length limit, but if you want to use more than 128 characters, please enter the desired
                        number
                        manually.
                    </li>
                    <li><b>Character Set:</b> Select the characters you want to include in your password by checking the
                        checkboxes.
                    </li>
                    <li><b>Number of Passwords:</b> Choose how many passwords you want to generate simultaneously.</li>
                </ul>
                <p>
                    {/*<picture>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/1.webp'} type="image/webp"/>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/1.jpg'} type="image/jpeg"/>*/}
                    {/*</picture>*/}
                    {/*<img src={process.env.PUBLIC_URL + '/img/11.jpg'} alt="Step1" width={970} height={203}/>*/}

                    <picture>
                        <source srcSet={process.env.PUBLIC_URL + '/img/11_mob.jpg'} media="(max-width: 1023px)"
                                width={779}
                                height={271}/>
                        <source srcSet={process.env.PUBLIC_URL + '/img/11.jpg'} media="(min-width: 1024px)" width={970}
                                height={202}/>
                        <img src={process.env.PUBLIC_URL + '/img/11.jpg'} alt="Password Generation Settings" width={970}
                             height={202}/>
                    </picture>
                    {/*<picture>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/2.webp'} type="image/webp"/>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/2.jpg'} type="image/jpeg"/>*/}
                    {/*</picture>*/}
                    {/*<img src={process.env.PUBLIC_URL + '/img/22.jpg'} alt="Step2" width={970} height={441}/>*/}
                    <picture>
                        <source srcSet={process.env.PUBLIC_URL + '/img/22_mob.jpg'} media="(max-width: 1023px)"
                                width={463}
                                height={602}/>
                        <source srcSet={process.env.PUBLIC_URL + '/img/22.jpg'} media="(min-width: 1024px)" width={1466}
                                height={569}/>
                        <img src={process.env.PUBLIC_URL + '/img/22.jpg'} alt="Password Generation Settings"
                             width={1466}
                             height={569}/>
                    </picture>
                </p>
                <h3>Step 2: Generate Secure Password</h3>
                <ul>
                    <li><b>Press the Generate Password</b> button to generate your new password.</li>
                    <li><b>The strength</b> of your generated password, <b>based on the settings you have chosen</b>,
                        will be
                        displayed below the password field.
                    </li>
                    <li><b>Press Copy button</b> to quickly add your generated passwords in the сlipboard.</li>
                </ul>
                <p><b>Additional:</b></p>
                <ul>
                    <li><b>Exclude Similar:</b> Excludes characters that look similar to make it easier to remember your
                        password.
                    </li>
                    <li><b>Exclude Duplicate:</b> Disallows the use of any character more than once, but adds a limit on
                        the
                        maximum length.
                    </li>
                    <li><b>Quantity:</b> Choose how many passwords you want to generate simultaneously.</li>
                </ul>
                <p>
                    {/*<picture>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/2.webp'} type="image/webp"/>*/}
                    {/*    <source srcSet={process.env.PUBLIC_URL + '/img/2.jpg'} type="image/jpeg"/>*/}
                    {/*</picture>*/}
                    {/*<img src={process.env.PUBLIC_URL + '/img/22.jpg'} alt="Step2" width={970} height={441}/>*/}
                    <picture>
                        <source srcSet={process.env.PUBLIC_URL + '/img/33_mob.jpg'} media="(max-width: 1023px)"
                                width={778}
                                height={353}/>
                        <source srcSet={process.env.PUBLIC_URL + '/img/33.jpg'} media="(min-width: 1024px)" width={970}
                                height={318}/>
                        <img src={process.env.PUBLIC_URL + '/img/33.jpg'} alt="Generate Secure Password" width={970}
                             height={318}/>
                    </picture>
                </p>
                <br/>
                <br/>
                <h2>What makes a password strong?</h2>
                <p>
                    To create a strong password follow the rules:
                </p>
                <p>
                    <b>Length:</b> The password should be of sufficient length, preferably 12 characters password or
                    more are
                    harder to crack.
                </p>
                <p>
                    <b>Complexity:</b> Strong password should include a combination of uppercase and lowercase letters,
                    numbers,
                    and special characters to increase its complexity.
                </p>
                <p>
                    <b>Unpredictability:</b> Avoid using common words phrases, recognizable words, personal information,
                    or
                    predictable patterns in your password to make it harder to guess.
                </p>
                <p>
                    <b>Uniqueness:</b> Each password should be unique for every account to prevent the domino effect of
                    a
                    compromised password leading to multiple breaches.
                </p>
                <br/>
                <br/>
                <h2>Are Online Password Generators Secure?</h2>
                <p>
                    Yes, password generators are generally secure, they use robust algorithms to generate random and
                    complex
                    passwords that are statistically difficult to guess or crack. However, it is essential to choose
                    trusted
                    password-maker software from a reliable source. This ensures that the password generator tool
                    employs strong
                    cryptographic techniques to generate passwords.
                </p>
            </Container>
        </Container>
    );
}


export default GeneratorFileContent;