export const languageFormatTemplate = () => {
    return [
        {"code": "af_ZA", "name": "Afrikaans (South Africa)", "codeFaker": "fakerAF_ZA"},
        {"code": "ar", "name": "Arabic", "codeFaker": "fakerAR"},
        {"code": "az", "name": "Azerbaijani", "codeFaker": "fakerAZ"},
        {"code": "base", "name": "Base", "codeFaker": "fakerBASE"},
        {"code": "cs_CZ", "name": "Czech (Czechia)", "codeFaker": "fakerCS_CZ"},
        {"code": "da", "name": "Danish", "codeFaker": "fakerDA"},
        {"code": "de", "name": "German", "codeFaker": "fakerDE"},
        {"code": "de_AT", "name": "German (Austria)", "codeFaker": "fakerDE_AT"},
        {"code": "de_CH", "name": "German (Switzerland)", "codeFaker": "fakerDE_CH"},
        {"code": "dv", "name": "Maldivian", "codeFaker": "fakerDV"},
        {"code": "el", "name": "Greek", "codeFaker": "fakerEL"},
        {"code": "en", "name": "English", "codeFaker": "fakerEN"},
        {"code": "en_AU", "name": "English (Australia)", "codeFaker": "fakerEN_AU"},
        {"code": "en_AU_ocker", "name": "English (Australia Ocker)", "codeFaker": "fakerEN_AU_ocker"},
        {"code": "en_BORK", "name": "English (Bork)", "codeFaker": "fakerEN_BORK"},
        {"code": "en_CA", "name": "English (Canada)", "codeFaker": "fakerEN_CA"},
        {"code": "en_GB", "name": "English (Great Britain)", "codeFaker": "fakerEN_GB"},
        {"code": "en_GH", "name": "English (Ghana)", "codeFaker": "fakerEN_GH"},
        {"code": "en_HK", "name": "English (Hong Kong)", "codeFaker": "fakerEN_HK"},
        {"code": "en_IE", "name": "English (Ireland)", "codeFaker": "fakerEN_IE"},
        {"code": "en_IN", "name": "English (India)", "codeFaker": "fakerEN_IN"},
        {"code": "en_NG", "name": "English (Nigeria)", "codeFaker": "fakerEN_NG"},
        {"code": "en_US", "name": "English (United States)", "codeFaker": "fakerEN_US"},
        {"code": "en_ZA", "name": "English (South Africa)", "codeFaker": "fakerEN_ZA"},
        {"code": "eo", "name": "Esperanto", "codeFaker": "fakerEO"},
        {"code": "es", "name": "Spanish", "codeFaker": "fakerES"},
        {"code": "es_MX", "name": "Spanish (Mexico)", "codeFaker": "fakerES_MX"},
        {"code": "fa", "name": "Farsi/Persian", "codeFaker": "fakerFA"},
        {"code": "fi", "name": "Finnish", "codeFaker": "fakerFI"},
        {"code": "fr", "name": "French", "codeFaker": "fakerFR"},
        {"code": "fr_BE", "name": "French (Belgium)", "codeFaker": "fakerFR_BE"},
        {"code": "fr_CA", "name": "French (Canada)", "codeFaker": "fakerFR_CA"},
        {"code": "fr_CH", "name": "French (Switzerland)", "codeFaker": "fakerFR_CH"},
        {"code": "fr_LU", "name": "French (Luxembourg)", "codeFaker": "fakerFR_LU"},
        {"code": "fr_SN", "name": "French (Senegal)", "codeFaker": "fakerFR_SN"},
        {"code": "he", "name": "Hebrew", "codeFaker": "fakerHE"},
        {"code": "hr", "name": "Croatian", "codeFaker": "fakerHR"},
        {"code": "hu", "name": "Hungarian", "codeFaker": "fakerHU"},
        {"code": "hy", "name": "Armenian", "codeFaker": "fakerHY"},
        {"code": "id_ID", "name": "Indonesian (Indonesia)", "codeFaker": "fakerID_ID"},
        {"code": "it", "name": "Italian", "codeFaker": "fakerIT"},
        {"code": "ja", "name": "Japanese", "codeFaker": "fakerJA"},
        {"code": "ka_GE", "name": "Georgian (Georgia)", "codeFaker": "fakerKA_GE"},
        {"code": "ko", "name": "Korean", "codeFaker": "fakerKO"},
        {"code": "lv", "name": "Latvian", "codeFaker": "fakerLV"},
        {"code": "mk", "name": "Macedonian", "codeFaker": "fakerMK"},
        {"code": "nb_NO", "name": "Norwegian (Norway)", "codeFaker": "fakerNB_NO"},
        {"code": "ne", "name": "Nepali", "codeFaker": "fakerNE"},
        {"code": "nl", "name": "Dutch", "codeFaker": "fakerNL"},
        {"code": "nl_BE", "name": "Dutch (Belgium)", "codeFaker": "fakerNL_BE"},
        {"code": "pl", "name": "Polish", "codeFaker": "fakerPL"},
        {"code": "pt_BR", "name": "Portuguese (Brazil)", "codeFaker": "fakerPT_BR"},
        {"code": "pt_PT", "name": "Portuguese (Portugal)", "codeFaker": "fakerPT_PT"},
        {"code": "ro", "name": "Romanian", "codeFaker": "fakerRO"},
        {"code": "ro_MD", "name": "Romanian (Moldova)", "codeFaker": "fakerRO_MD"},
        {"code": "ru", "name": "Russian", "codeFaker": "fakerRU"},
        {"code": "sk", "name": "Slovak", "codeFaker": "fakerSK"},
        {"code": "sr_RS_latin", "name": "Serbian (Serbia, Latin)", "codeFaker": "fakerSR_RS_latin"},
        {"code": "sv", "name": "Swedish", "codeFaker": "fakerSV"},
        {"code": "th", "name": "Thai", "codeFaker": "fakerTH"},
        {"code": "tr", "name": "Turkish", "codeFaker": "fakerTR"},
        {"code": "uk", "name": "Ukrainian", "codeFaker": "fakerUK"},
        {"code": "ur", "name": "Urdu", "codeFaker": "fakerUR"},
        {"code": "vi", "name": "Vietnamese", "codeFaker": "fakerVI"},
        {"code": "yo_NG", "name": "Yoruba (Nigeria)", "codeFaker": "fakerYO_NG"},
        {"code": "zh_CN", "name": "Chinese (China)", "codeFaker": "fakerZH_CN"},
        {"code": "zh_TW", "name": "Chinese (Taiwan)", "codeFaker": "fakerZH_TW"},
        {"code": "zu_ZA", "name": "Zulu (South Africa)", "codeFaker": "fakerZU_ZA"}
    ];
};