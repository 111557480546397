import React, {useContext, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {AuthContext} from "../context/AuthContext";
import {Container} from 'react-bootstrap';

export const Navbar = () => {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);

    const logoutHandler = event => {
        event.preventDefault();
        auth.logout();
        navigate('/');
    };


    const Nav = () => {
        const [adminLinks] = useState([
            {
                url: `/admin/pass_gen_logs`,
                title: `Pass Gen Logs`
            },
            {
                url: `/admin/data_gen_logs`,
                title: `Data Gen logs`
            },
            {
                url: `/admin/ai_logs`,
                title: `AI logs`
            }
        ]);

        const [links] = useState([
            {
                url: `password-generator`,
                title: `Pass Gen`
            },
            {
                url: `data-generator`,
                title: `Data Gen`
            },
        ]);

        return (
            <>
                {
                    auth.isAdmin ?
                        adminLinks.map((item, i) => {
                            return (
                                <li key={i}>
                                    <NavLink to={item.url} end>
                                        {item.title}
                                    </NavLink>
                                </li>
                            );
                        }) : <></>
                }
                {
                    links.map((item, i) => {
                        return (
                            <li key={i}>
                                <NavLink to={item.url} end>
                                    {item.title}
                                </NavLink>
                            </li>
                        );
                    })
                }
            </>
        );
    };

    const Logout = () => {
        if (!auth.userId) {
            return;
            // return (<li><NavLink to={`/login`} className="btn btn-primary" end>Sign In</NavLink></li>);
        }

        return (
            <li><a href="/" className="btn btn-primary" onClick={logoutHandler}>Sign out</a></li>
        );
    };

    return (
        <nav className={`header`}>
            <Container>
                <div className="nav-wrapper">
                    <NavLink to="/" className="brand-logo" aria-label={`Random and Secure`}>
                        <svg width="165" height="36" viewBox="0 0 165 36" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.89655 0H163.103C164.147 0 165 0.81 165 1.8V34.2C165 35.19 164.147 36 163.103 36H1.89655C0.853448 36 0 35.19 0 34.2V1.8C0 0.81 0.853448 0 1.89655 0Z"
                                fill="#2C2C2C"/>
                            <path
                                d="M13.4512 8.99998V11.16H14.7066C15.2446 11.25 17.0381 12.69 17.3968 13.05C17.7555 13.41 18.7419 14.22 18.7419 14.76V17.1H22.1495V13.86H19.7283C19.1903 13.77 18.0245 13.14 17.6658 12.87C17.3071 12.51 15.7827 10.8 15.693 10.26V8.99998H13.4512ZM25.6468 27L21.7908 21.87H16.5898V27H13.4512V15.03V13.14H15.155C15.962 13.14 16.5898 13.86 16.5898 14.67V18.9H24.0327V11.88H19.549C18.7419 11.88 18.0245 11.25 18.0245 10.53V8.99998H22.2392C24.1224 8.99998 25.8262 9.71999 27.0816 10.89C28.337 12.06 29.0544 13.68 29.0544 15.39C29.0544 17.19 28.2474 18.72 27.0816 19.89C26.5436 20.43 25.9158 20.79 25.2881 21.15L29.6821 26.91H25.6468V27Z"
                                fill="#2987F8"/>
                            <path
                                d="M43.2233 12.96V27H40.6228L34.3456 18.09H34.2559V27H31.207V12.96H33.8972L40.1744 21.69H40.2641V12.96H43.2233Z"
                                fill="white"/>
                            <path
                                d="M50.3966 26.91H45.2852V12.96H50.4862C51.921 12.96 53.1765 13.23 54.2525 13.77C55.3286 14.31 56.1357 15.12 56.6737 16.11C57.2118 17.1 57.5705 18.36 57.5705 19.8C57.5705 21.24 57.3015 22.59 56.6737 23.58C56.1357 24.66 55.3286 25.38 54.2525 26.01C53.1765 26.64 51.921 26.91 50.3966 26.91ZM48.3341 24.3H50.3069C51.2036 24.3 52.0107 24.12 52.5487 23.85C53.1765 23.58 53.6248 23.04 53.9835 22.41C54.3422 21.78 54.4319 20.88 54.4319 19.89C54.4319 18.9 54.2525 18 53.9835 17.37C53.7145 16.74 53.1765 16.2 52.5487 15.93C51.921 15.66 51.2036 15.48 50.3069 15.48H48.3341V24.3Z"
                                fill="white"/>
                            <path
                                d="M68.87 17.46C68.7803 17.1 68.601 16.83 68.4216 16.56C68.2423 16.29 68.0629 16.11 67.7939 15.93C67.5249 15.75 67.2559 15.57 66.8972 15.48C66.5385 15.39 66.1798 15.3 65.8211 15.3C65.1037 15.3 64.476 15.48 63.8482 15.84C63.3102 16.2 62.8618 16.74 62.5928 17.37C62.3238 18 62.1444 18.9 62.1444 19.8C62.1444 20.79 62.3238 21.6 62.5928 22.23C62.8618 22.95 63.3102 23.4 63.8482 23.76C64.3863 24.12 65.1037 24.3 65.8211 24.3C66.5385 24.3 67.0765 24.21 67.6146 23.94C68.1526 23.67 68.5113 23.4 68.7803 22.95C69.0493 22.5 69.139 21.96 69.139 21.42L69.7667 21.51H66.0004V19.26H72.0086V20.97C72.0086 22.23 71.7396 23.22 71.2015 24.12C70.6635 25.02 69.9461 25.65 68.9597 26.1C68.1526 26.73 67.0765 27 65.9108 27C64.5656 27 63.3999 26.73 62.3238 26.1C61.3374 25.56 60.5303 24.66 59.9026 23.67C59.3646 22.59 59.0059 21.33 59.0059 19.89C59.0059 18.81 59.1852 17.73 59.5439 16.92C59.9026 16.02 60.351 15.3 60.9787 14.67C61.6064 14.04 62.3238 13.59 63.1309 13.23C63.9379 12.87 64.8347 12.78 65.8211 12.78C66.6281 12.78 67.4352 12.87 68.1526 13.14C68.87 13.41 69.4977 13.68 70.0358 14.13C70.5738 14.58 71.0222 15.03 71.3809 15.57C71.7396 16.11 71.9189 16.74 72.0086 17.46H68.87Z"
                                fill="white"/>
                            <path
                                d="M73.9814 27V12.96H83.7559V15.39H77.0304V18.72H83.2179V21.15H77.0304V24.48H83.7559V27H73.9814Z"
                                fill="white"/>
                            <path
                                d="M97.8345 12.96V27H95.2339L88.9568 18.09H88.7774V27H85.7285V12.96H88.4187L94.6959 21.69H94.7856V12.96H97.8345Z"
                                fill="white"/>
                            <path
                                d="M102.498 27C102.049 27 101.601 26.82 101.332 26.55C100.973 26.19 100.794 25.83 100.794 25.38C100.794 24.93 100.973 24.57 101.332 24.21C101.691 23.85 102.049 23.76 102.498 23.76C102.946 23.76 103.305 23.94 103.664 24.21C104.022 24.57 104.202 24.93 104.202 25.38C104.202 25.65 104.112 25.92 103.933 26.19C103.753 26.46 103.574 26.64 103.305 26.82C103.036 26.91 102.767 27 102.498 27Z"
                                fill="white"/>
                            <path
                                d="M118.998 17.82H115.949C115.859 17.46 115.769 17.1 115.59 16.83C115.411 16.56 115.231 16.29 114.962 16.02C114.693 15.84 114.335 15.66 113.976 15.57C113.617 15.48 113.259 15.39 112.81 15.39C112.093 15.39 111.375 15.57 110.837 15.93C110.299 16.29 109.851 16.83 109.582 17.46C109.313 18.09 109.134 18.99 109.134 19.89C109.134 20.88 109.313 21.69 109.582 22.41C109.851 23.04 110.299 23.58 110.837 23.94C111.375 24.3 112.003 24.48 112.81 24.48C113.259 24.48 113.617 24.39 113.976 24.3C114.335 24.21 114.604 24.03 114.873 23.85C115.142 23.67 115.411 23.4 115.59 23.13C115.769 22.86 115.859 22.5 115.949 22.14H118.998C118.908 22.77 118.729 23.4 118.37 23.94C118.011 24.57 117.653 25.02 117.115 25.56C116.576 26.01 115.949 26.37 115.231 26.64C114.514 26.91 113.707 27 112.72 27C111.465 27 110.299 26.73 109.223 26.19C108.237 25.65 107.43 24.84 106.802 23.76C106.174 22.68 105.905 21.42 105.905 19.89C105.905 18.36 106.174 17.1 106.802 16.02C107.43 14.94 108.237 14.13 109.223 13.59C110.21 13.05 111.375 12.78 112.631 12.78C113.438 12.78 114.245 12.87 114.962 13.14C115.68 13.41 116.307 13.68 116.845 14.13C117.384 14.58 117.832 15.12 118.191 15.75C118.639 16.29 118.908 17.01 118.998 17.82Z"
                                fill="white"/>
                            <path
                                d="M134.062 19.89C134.062 21.42 133.793 22.68 133.166 23.76C132.538 24.84 131.731 25.65 130.744 26.19C129.758 26.73 128.592 27 127.337 27C126.081 27 124.916 26.73 123.839 26.19C122.853 25.65 122.046 24.84 121.418 23.76C120.791 22.68 120.521 21.42 120.521 19.89C120.521 18.36 120.791 17.1 121.418 16.02C122.046 14.94 122.853 14.13 123.839 13.59C124.826 13.05 125.992 12.78 127.337 12.78C128.592 12.78 129.758 13.05 130.744 13.59C131.731 14.13 132.538 14.94 133.166 16.02C133.704 17.1 134.062 18.36 134.062 19.89ZM130.924 19.89C130.924 18.9 130.744 18.09 130.475 17.37C130.206 16.74 129.758 16.2 129.22 15.84C128.682 15.48 128.054 15.3 127.247 15.3C126.53 15.3 125.902 15.48 125.274 15.84C124.736 16.2 124.288 16.74 124.019 17.37C123.75 18 123.57 18.9 123.57 19.89C123.57 20.88 123.75 21.69 124.019 22.41C124.288 23.04 124.736 23.58 125.274 23.94C125.812 24.3 126.44 24.48 127.247 24.48C127.964 24.48 128.592 24.3 129.22 23.94C129.758 23.58 130.206 23.04 130.475 22.41C130.834 21.69 130.924 20.88 130.924 19.89Z"
                                fill="white"/>
                            <path
                                d="M135.856 12.96H139.623L143.568 22.32H143.748L147.693 12.96H151.46V27H148.5V17.82H148.411L144.644 27H142.582L138.995 17.73H138.905V27H135.946V12.96H135.856Z"
                                fill="white"/>
                        </svg>
                    </NavLink>
                    <ul id="nav-mobile" className="right hide-on-med-and-down">
                        <Nav/>
                        <Logout/>
                    </ul>
                </div>
            </Container>
        </nav>
    );
};