import React, {useCallback, useContext, useEffect, useState} from 'react';
import {PasswordContext} from '../../context/PasswordContext';
import {Button, Col, Container, Row, Form, Stack} from 'react-bootstrap';
import {Lock, ResetIcon} from '../Icons';
import {ComponentSize} from './ComponentSize';
import {ComponentResult} from './ComponentResult';
import '../../assets/sliderMarks.scss';
import '../../assets/password.scss';
import {ComponentSecuriry} from './ComponentSecuriry';
import {CookieContext} from '../../context/CookieContext';
import content from '../../config/content';
import Widget3 from '../Widgets/Widget_3';
import Widget2 from '../Widgets/Widget_2';


export const GenerateContainer = () => {
    const cookie = useContext(CookieContext);
    const generate = useContext(PasswordContext);

    const [isLoading, setLoading] = useState(false);
    const [generateStart, setGenerateStart] = useState(cookie.global.has(`passwordGenStart`));
    const [saveCache, setSaveCache] = useState(cookie.global.has(`passwordGen`));
    const [result, setResult] = useState('');
    const [numberPasswords, setNumberPasswords] = useState(cookie.global.getByKeyDef(`passwordGen`, 1, `numberPassword`));
    const [disable, setDisable] = useState(false);
    const [optionNumbers, setOptionNumbers] = useState(cookie.global.getByKeyDef(`passwordGen`, true, `numbers`));
    const [optionSymbol, setOptionSymbol] = useState(cookie.global.getByKeyDef(`passwordGen`, true, `symbols`));
    const [optionUppercase, setOptionUppercase] = useState(cookie.global.getByKeyDef(`passwordGen`, true, `uppercase`));
    const [optionLowercase, setOptionLowercase] = useState(cookie.global.getByKeyDef(`passwordGen`, true, `lowercase`));
    const [optionExcludeSimilarCharacters, setOptionExcludeSimilarCharacters] = useState(cookie.global.getByKeyDef(`passwordGen`, false, `excludeSimilarCharacters`));
    const [optionExcludeDuplicate, setOptionExcludeDuplicate] = useState(cookie.global.getByKeyDef(`passwordGen`, false, `duplicate`));
    const [optionAdditionalSymbols, setOptionAdditionalSymbols] = useState(cookie.global.getByKeyDef(`passwordGen`, false, `symbols_additional`));
    const [options, setOptions] = useState({
        length: generate.setting.sizePassword,
        numbers: optionNumbers,
        symbols: optionSymbol,
        symbols_additional: optionAdditionalSymbols,
        uppercase: optionUppercase,
        lowercase: optionLowercase,
        excludeSimilarCharacters: optionExcludeSimilarCharacters,
        duplicate: optionExcludeDuplicate
    });

    const [changeGenerate, setChangeGenerate] = useState(1);
    const [changeGenerateSecurity, setChangeGenerateSecurity] = useState(1);
    const incrementChangeGenerate = () => {
        setChangeGenerate(changeGenerate + 1);
    };
    const incrementChangeGenerateSecurity = () => {
        setChangeGenerateSecurity(changeGenerateSecurity + 1);
    };


    const generateHandler = () => {
        setLoading(false);
        let res = numberPasswords === 1
            ? generate.generate(options)
            : generate.generateMultiple(numberPasswords, options);
        setResult(res);

        generate.fetchLogHandler({
            'pass_length': options.length,
            'pass_options': {
                numbers: options.numbers,
                symbols: options.symbols,
                symbols_additional: options.symbols_additional,
                uppercase: options.uppercase,
                lowercase: options.lowercase,
                excludeSimilarCharacters: options.excludeSimilarCharacters,
                duplicate: options.duplicate,
                numberPasswords: numberPasswords
            }
        });

        saveCacheHandler();
    };

    const saveCacheHandler = () => {
        if (saveCache) {
            cookie.global.set(`passwordGen`, {
                ...options,
                ...{numberPassword: numberPasswords}
            });
        }
    };
    const saveCacheClickHandler = () => {
        setSaveCache(!saveCache);
        saveCacheHandler();
    };

    useEffect(() => {
        incrementChangeGenerate();
    }, [result, disable, numberPasswords]);

    useEffect(() => {
        incrementChangeGenerateSecurity();
    }, [result, options, disable]);

    useEffect(() => {
        setLoading(true);
    }, [result]);

    useEffect(() => {
        if (isLoading) {
            setResult('');
        }
        setLoading(true);
        saveCacheHandler();
    }, [options, disable]);

    useEffect(() => {
        if (generateStart) {
            generateHandler();
        }
    }, []);

    useEffect(() => {
        if (!generateStart) {
            cookie.global.remove(`passwordGenStart`);
        } else {
            cookie.global.set(`passwordGenStart`, true);
        }
    }, [generateStart]);

    useEffect(() => {
        if (!saveCache) {
            cookie.global.remove(`passwordGen`);
        }
        saveCacheHandler();
    }, [saveCache]);

    useEffect(() => {
        if (disable) setResult('');
    }, [disable]);

    useEffect(() => {
        if (isLoading) {
            setOptions({
                length: generate.setting.sizePassword,
                numbers: optionNumbers,
                symbols: optionSymbol,
                symbols_additional: optionAdditionalSymbols,
                uppercase: optionUppercase,
                lowercase: optionLowercase,
                excludeSimilarCharacters: optionExcludeSimilarCharacters,
                duplicate: optionExcludeDuplicate
            });
        }
        let dis = !optionNumbers && !optionSymbol && !optionAdditionalSymbols && !optionUppercase && !optionLowercase;
        setDisable(dis);
    }, [
        generate.setting.sizePassword,
        numberPasswords,
        optionNumbers,
        optionSymbol,
        optionAdditionalSymbols,
        optionUppercase,
        optionLowercase,
        optionExcludeSimilarCharacters,
        optionExcludeDuplicate
    ]);

    const resetHandler = useCallback(() => {
        setOptionNumbers(true);
        setOptionSymbol(true);
        setOptionUppercase(true);
        setOptionLowercase(true);
        setOptionExcludeSimilarCharacters(false);
        setOptionExcludeDuplicate(false);
        setOptionAdditionalSymbols(false);
        setResult('');
        setNumberPasswords(1);
        generate.setting.setSizePassword(generate.setting.defaultSizePassword);
    }, []);


    return (
        <Container>
            <Stack direction="horizontal" gap={3} className="w-100 generator-stack-top">
                <div className="generator-stack-top-title">
                    <h1>{content.password_page.content.title}</h1>
                </div>
                <div className={`ms-auto generator-stack-top-btn generator-stack-top-btn-dash`}>
                    <Button variant="primary"
                            onClick={resetHandler}
                    >
                        <ResetIcon/> Reset All
                    </Button>
                </div>
                <div className={`generator-stack-top-btn`}>
                    <Form.Check
                        type={`checkbox`}
                        className={`switch-new`}
                        id={`generate-at-start`}
                        disabled={!cookie.cookieStatus}
                    >
                        <Form.Check.Input
                            type={`checkbox`}
                            checked={generateStart}
                            onChange={() => setGenerateStart(!generateStart)}
                            disabled={!cookie.cookieStatus}
                        />
                        <Form.Check.Label><span>Generate at Start</span></Form.Check.Label>
                    </Form.Check>
                </div>
                <div className={`generator-stack-top-btn`}>
                    <Form.Check
                        type={`checkbox`}
                        className={`switch-new`}
                        id={`save-in-cache`}
                        disabled={!cookie.cookieStatus}
                    >
                        <Form.Check.Input
                            type={`checkbox`}
                            checked={saveCache}
                            onChange={saveCacheClickHandler}
                            disabled={!cookie.cookieStatus}
                        />
                        <Form.Check.Label><span>Save  in Cache</span></Form.Check.Label>
                    </Form.Check>
                </div>
            </Stack>
            <div className={`container-color-2 generator-container`}>
                <Widget2/>
                <Widget3/>
                <ComponentSize/>
                <Row>
                    <Col lg={{offset: 3, span: 6}}>
                        <div className={`password-option-panel`}>
                            <div className={`password-option-item`}>
                                <label>Digits</label>
                                <Form.Check
                                    type={`checkbox`}
                                    label={`1234567890`}
                                    checked={optionNumbers}
                                    onChange={() => setOptionNumbers(!optionNumbers)}
                                />
                            </div>
                            <div className={`password-option-item`}>
                                <label>Lower Case</label>
                                <Form.Check
                                    type={`checkbox`}
                                    label={`abcdefg`}
                                    checked={optionLowercase}
                                    onChange={() => setOptionLowercase(!optionLowercase)}
                                />
                            </div>
                            <div className={`password-option-item`}>
                                <label>Upper Case</label>
                                <Form.Check
                                    type={`checkbox`}
                                    label={`ABCDEFG`}
                                    checked={optionUppercase}
                                    onChange={() => setOptionUppercase(!optionUppercase)}
                                />
                            </div>
                            <div className={`password-option-item`}>
                                <label>Symbols</label>
                                <Form.Check
                                    type={`checkbox`}
                                    label={`@#$%!?&*^=_`}
                                    checked={optionSymbol}
                                    onChange={() => setOptionSymbol(!optionSymbol)}
                                />
                            </div>
                            <div className={`password-option-item`}>
                                <label>Additional Symbols</label>
                                <Form.Check
                                    type={`checkbox`}
                                    label={`()+-{}[]|:;"/.><,\`~`}
                                    checked={optionAdditionalSymbols}
                                    onChange={() => setOptionAdditionalSymbols(!optionAdditionalSymbols)}
                                />
                            </div>
                            <hr/>
                            <div className={`password-option-item`}>
                                <label>Exclude Similar</label>
                                <Form.Check
                                    type={`checkbox`}
                                    label={`ilLI|\`oO0`}
                                    checked={optionExcludeSimilarCharacters}
                                    onChange={() => setOptionExcludeSimilarCharacters(!optionExcludeSimilarCharacters)}
                                />
                            </div>
                            <div className={`password-option-item`}>
                                <label>Exclude Duplicate</label>
                                <Form.Check
                                    type={`checkbox`}
                                >
                                    <Form.Check.Input
                                        type={`checkbox`}
                                        checked={optionExcludeDuplicate}
                                        onChange={() => setOptionExcludeDuplicate(!optionExcludeDuplicate)}
                                    />
                                    <Form.Check.Label><span>a</span>XXXX<span>a</span>XX</Form.Check.Label>
                                </Form.Check>
                            </div>
                            <div className={`password-option-item`}>
                                <label>Number of Passwords</label>
                                <div>
                                    <Form.Control
                                        type={`number`}
                                        defaultValue={numberPasswords}
                                        onChange={e => setNumberPasswords(parseInt(e.target.value))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={`password-generate-btn`}>
                            <Button
                                onClick={generateHandler}
                                disabled={disable}
                            >
                                <Lock/> Generate Password
                            </Button>
                        </div>
                    </Col>
                </Row>
                <ComponentResult result={result} disable={disable} options={options} numberPasswords={numberPasswords}
                                 key={`componen-result-${changeGenerate}`}/>
                <ComponentSecuriry options={options} key={`component-security-${changeGenerateSecurity}`}/>
            </div>
        </Container>
    );
};